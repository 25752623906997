import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Grid, Paper, ShipsIcon, InfoBox, Tabs, Tab, Slide, Stack } from '@esgian/esgianui';
import { TextWithTooltipIcon } from '@components';
import {
  PortsInCountryTable,
  UpcomingVesselPortCallsTable,
  VesselPortCallsTable
} from '@components/Tables';
import { CountryPortsMap } from '@components/Maps';
import { PortTimeVsPortCallsChart, PortTimeYtdComparisonChart } from '@components/Charts';
import { GEOGRAPHICAL_TYPES, MAP_STYLE_SATELLITE_MODE, SEGMENT } from '@constants';
import PortWaitingTimeSection from '../PortProfileSection/SubSections/PortWaitingTimeSection';
import { useDispatch, useSelector } from 'react-redux';
import { getSegment, setGeographicalType } from '@store/features';
import { useSegment } from '@hooks';

function CountryProfileSection({
  loading,
  countryDetailData,
  countryPortCallsData,
  countryPortsData,
  countryPortStatsData,
  portCongestionData,
  countryUpcomingPortCallsData,
  geoId
}) {
  const [section, setSection] = useState(1);
  const [init, setInit] = useState(false);
  const { ytd, lastYearPortCalls, prevYearPortCallsYTD } = countryPortStatsData || {};
  const { countryName } = countryDetailData || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const segment = useSelector(getSegment);
  const dispatch = useDispatch();
  const isProd = process.env.CURRENT_ENV === 'prod';
  const { isRoRo } = useSegment();

  const handleSectionChange = useCallback(
    (_, val) => {
      if (init) {
        searchParams.set('section', val);
        setSearchParams(searchParams);
        setSection(val);
      }
    },
    [init]
  );

  useEffect(() => {
    dispatch(setGeographicalType(GEOGRAPHICAL_TYPES.COUNTRY));
  }, []);

  useEffect(() => {
    if (searchParams.has('section')) {
      setSection(parseInt(searchParams.get('section')));
    }
    setInit(true);
    return () => {
      searchParams.delete('section');
    };
  }, []);

  useEffect(() => {
    if (!countryPortStatsData?.polyFit) {
      handleSectionChange(null, 1);
    }
  }, [countryPortStatsData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InfoBox
          loading={loading}
          mainValue={ytd}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          header={'Port Calls (YTD)'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
        />
      </Grid>
      <Grid item xs={4}>
        <InfoBox
          loading={loading}
          mainValue={prevYearPortCallsYTD}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          header={`${moment().subtract(12, 'months').format('yyyy')} Port Calls YTD`}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
        />
      </Grid>
      <Grid item xs={4}>
        <InfoBox
          loading={loading}
          mainValue={lastYearPortCalls}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          header={`${moment().subtract(12, 'months').format('yyyy')} # Port Calls`}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
        />
      </Grid>
      <Grid item xs={12} id={'country-profile-overview'}>
        <Paper>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Tabs value={section} onChange={handleSectionChange} variant="scrollable">
              <Tab value={1} label="Overview" />
              {countryPortStatsData?.polyFit && <Tab value={2} label={'port statistics'} />}
              <Tab value={3} label="Port Waiting Time" />
            </Tabs>
            {!countryPortStatsData?.polyFit && (
              <TextWithTooltipIcon
                color={'text.secondary'}
                label="PORT STATISTICS"
                tooltipText="Insufficient data to compute the statistics"
              />
            )}
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <>
          <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
            <Stack>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <PortsInCountryTable
                    countryName={countryName}
                    loading={loading}
                    countryPortsData={countryPortsData}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Paper>
                    <CountryPortsMap
                      mapStyle={MAP_STYLE_SATELLITE_MODE}
                      height={'40vh'}
                      loading={loading}
                      portData={countryPortsData}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <VesselPortCallsTable
                    loading={loading}
                    name={countryName}
                    geoId={geoId}
                    title={'Most Recent Vessels Port Calls'}
                    portCalls={countryPortCallsData}
                    assetLevel={'country'}
                    labels={[
                      'Vessel',
                      'IMO',
                      'Operator',
                      'Port',
                      'Country',
                      'UNLOCODE',
                      'Arrival',
                      'Departure',
                      'Port Time (hours)'
                    ]}
                    headers={[
                      'vesselName',
                      'imo',
                      'operatorShortName',
                      'portName',
                      'countryName',
                      'portCode',
                      'startDate',
                      'endDate',
                      'timeInPort'
                    ]}
                    allowDownloadAll={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UpcomingVesselPortCallsTable
                    assetLevel={'port'}
                    title={'Upcoming Vessels Port Calls'}
                    upcomingPortCalls={countryUpcomingPortCallsData}
                    loading={loading}
                    name={countryName}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Slide>
          <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PortTimeVsPortCallsChart
                    loading={loading}
                    portName={countryName}
                    portStats={countryPortStatsData}
                    fileName={`port-time-vs-port-calls-${countryName
                      ?.toLowerCase()
                      .split(' ')
                      .join('-')}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PortTimeYtdComparisonChart
                    portStats={countryPortStatsData}
                    loading={loading}
                    portName={countryName}
                    fileName={`port-time-change-${countryName?.toLowerCase().split(' ').join('-')}`}
                  />
                </Grid>
              </Grid>
            </div>
          </Slide>
          <Slide direction="right" in={section === 3} mountOnEnter unmountOnExit>
            <div>
              <PortWaitingTimeSection
                assetLevel={'port'}
                portCongestionData={portCongestionData}
                loading={loading}
              />
            </div>
          </Slide>
        </>
      </Grid>
    </Grid>
  );
}

CountryProfileSection.propTypes = {
  loading: PropTypes.bool,
  portData: PropTypes.object,
  countryDetailData: PropTypes.object,
  countryPortsData: PropTypes.arrayOf(PropTypes.object),
  countryPortStatsData: PropTypes.object,
  countryUpcomingPortCallsData: PropTypes.arrayOf(PropTypes.object),
  countryPortCallsData: PropTypes.arrayOf(PropTypes.object),
  portCongestionData: PropTypes.object
};

CountryProfileSection.defaultProps = {
  loading: false,
  portCongestionData: null,
  portData: null,
  countryDetailData: null,
  countryPortCallsData: [],
  countryPortStatsData: null,
  countryPortsData: [],
  countryUpcomingPortCallsData: []
};

export default CountryProfileSection;
