import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '../TableWithSummary/TableWithSummary';
import { OVERALL_VOYAGE_PHASE_IN_FACTOR, OVERALL_VOYAGE_EU_ALLOWANCES } from '@constants/tooltips';
import { formatNumberReadable } from '@helpers';

const OverallVoyageExpandedEstimatedEtsTable = ({ overallVoyageEuEtsAllowanceCost, isLoading }) => {
  const [tableData, setTableData] = useState([]);

  const columns = [
    { name: 'voyageLeg', label: 'Voyage Leg' },
    { name: 'origin', label: 'Origin' },
    { name: 'destination', label: 'Destination' },
    { name: 'co2Emission', label: 'CO₂ Emission' },
    { name: 'applicability', label: 'Applicability', tooltip: OVERALL_VOYAGE_EU_ALLOWANCES },
    { name: 'phaseInFactor', label: 'Phase in factor', tooltip: OVERALL_VOYAGE_PHASE_IN_FACTOR },
    {
      name: 'etsAllowance',
      label: 'EU Allowances (EUA)',
      tooltip:
        'EU Allowances (EUAs) are a type of carbon allowance that allows companies covered by the EU ETS to emit a certain amount of  CO₂e. EUAs can be bought and sold on the market, and the variable market price of EUAs reflects the cost of reducing emissions.'
    },
    { name: 'etsCostPerTCo2Euro', label: 'EU ETS Cost per Tonne CO₂ (€)' },
    { name: 'estimatedEtsCostEuro', label: 'EU ETS Cost (€)' }
  ];

  useEffect(() => {
    if (!overallVoyageEuEtsAllowanceCost) return;

    const voyageLegData = createVoyageLegData(overallVoyageEuEtsAllowanceCost);
    const totalRow = createTotalRow(overallVoyageEuEtsAllowanceCost);

    setTableData([...voyageLegData, totalRow]);
  }, [overallVoyageEuEtsAllowanceCost]);

  const createVoyageLegData = (data) => {
    return data.voyageLegEuEtsAllowanceCosts.map((d, i) => ({
      voyageLeg: { value: `#${i + 1}` },
      origin: { value: d.fromPortName },
      destination: { value: d.toPortName },
      co2Emission: { value: formatNumberReadable(d.co2Tn?.toFixed(0)) },
      applicability: { value: `${d.applicabilityFactor ?? '--'}%` },
      phaseInFactor: { value: d.phaseInFactor },
      etsAllowance: { value: formatNumberReadable(d.euEtsAllowanceTn?.toFixed(1)) },
      etsCostPerTCo2Euro: { value: d.etsCostPerTonCo2?.toFixed(2) },
      estimatedEtsCostEuro: { value: formatNumberReadable(d.estimatedEtsCost?.toFixed(0)) }
    }));
  };

  const createTotalRow = (data) => ({
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    co2Emission: {
      value: formatNumberReadable(data?.totalCo2Tn?.toFixed(0)) ?? '-'
    },
    applicability: { value: '-' },
    phaseInFactor: { value: '-' },
    etsAllowance: {
      value: formatNumberReadable(data?.totalEtsAllowanceTn?.toFixed(1)) ?? '-'
    },
    etsCostPerTCo2Euro: { value: '-' },
    estimatedEtsCostEuro: {
      value: formatNumberReadable(data?.totalEstimatedEtsCost?.toFixed(0)) ?? '--'
    }
  });

  return (
    <TableWithSummary
      title="Total ETS Cost for overall voyage"
      columns={columns}
      data={tableData}
      isLoading={isLoading}
    />
  );
};

OverallVoyageExpandedEstimatedEtsTable.propTypes = {
  overallVoyageEuEtsAllowanceCost: PropTypes.object,
  isLoading: PropTypes.bool
};

export default OverallVoyageExpandedEstimatedEtsTable;
