import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import {
  CountryFlag,
  Divider,
  Grid,
  Link,
  Paper,
  Skeleton,
  Stack,
  Typography,
  Box,
  DateRangeMobi,
  NotFound,
  Select,
  MenuItem
} from '@esgian/esgianui';
import { VesselsMap } from '@components/Maps';
import { TextWithTooltipIcon } from '@components';
import { VesselsTable } from '@components/Tables';
import { OperatorFleetAgeChart, OperatorCEUChart } from './index';
import { MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import { useSegment, useTheme } from '@hooks';
import { fetchVesselStats } from '@api';
import { DATE_TIME_FORMAT } from '@constants';
import { useParams } from 'react-router-dom';
import moment from 'moment';

function OperatorOverviewSection({ operatorData, vesselsData }) {
  const { isRoRo, segment } = useSegment();
  const { theme } = useTheme();
  const params = useParams();
  const [selectedFilter, setSelectedFilter] = useState('ageProfile');

  const filterOptions = [
    { value: 'ceuCapacity', label: isRoRo ? 'CEU Capacity' : 'Total Deadweight' },
    { value: 'ageProfile', label: 'Age Profile' }
  ];

  const [fleetDates, setFleetDates] = useState({
    start: moment().subtract(6, 'months').startOf('month'),
    end: moment()
  });

  const {
    data: fleetData,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['fleetData', segment.id, params.id, fleetDates.start, fleetDates.end],
    queryFn: () =>
      fetchVesselStats(
        null,
        [segment.id],
        [],
        [params.id],
        [],
        [],
        [],
        moment(fleetDates.start).format(DATE_TIME_FORMAT),
        moment(fleetDates.end).format(DATE_TIME_FORMAT)
      )
  });

  const processFleetData = (data) => {
    if (!data || !data[0] || !data[0].vesselTimeSeries) return {};

    const operatorFleetData = data[0].vesselTimeSeries;
    const categories = operatorFleetData.map((item) => {
      const date = new Date(item.date);
      return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    });

    return {
      categories,
      fleetAgeCeuData: operatorFleetData.map((item) => item.ageInformation || {}),
      CEUCapacityData: operatorFleetData.map((item) => item.capacity || {}),
      vesselsAdded: operatorFleetData.map((item) => item.vesselsIn || {}),
      vesselsRemoved: operatorFleetData.map((item) => item.vesselsOut || {}),
      totalCountVessel: operatorFleetData.map((item) => item.numVessels || {})
    };
  };

  const {
    categories,
    fleetAgeCeuData,
    CEUCapacityData,
    vesselsAdded,
    vesselsRemoved,
    totalCountVessel
  } = processFleetData(fleetData);

  const ensureHttpsAndWww = (website) => {
    let site = website;
    if (!website.startsWith('www.') && !website.startsWith('https://')) {
      site = `https://www.${site}`;
    } else if (website.startsWith('www.')) {
      site = `https://${site}`;
    }
    return site;
  };

  const {
    country,
    address,
    website,
    operatorLongName,
    operatorShortName,
    operatorVessels,
    isoAlpha2
  } = operatorData || {};

  const hideColumns = isRoRo
    ? ['operatorShortName', 'maxLiftingCapacity', 'numCranes']
    : ['operatorShortName', 'loadCapacity', 'callSign', 'ownerLongName', 'class', 'grossTonnage'];

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper sx={{ p: 2, height: '100%' }}>
              <Grid container spacing={2}>
                <Grid item container spacing={2} xs={12}>
                  <Grid item alignSelf={'center'}>
                    <Typography variant={'h6'}>Overview</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {!isLoading ? (
                  <>
                    <Grid item container spacing={2}>
                      <Grid item xs={2.5}>
                        <Typography variant={'body2'} bold>
                          Name:{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant={'body2'}>{operatorLongName}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={2.5}>
                        <Typography variant={'body2'} bold>
                          Short Name:{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant={'body2'}>{operatorShortName}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={2.5}>
                        <Typography variant={'body2'} bold>
                          Country:{' '}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                          <CountryFlag size={15} countryCode={isoAlpha2} />
                          <Typography variant={'body2'}>{country}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={2.5}>
                        <Typography variant={'body2'} bold>
                          Address:{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant={'body2'}>{address}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={2.5}>
                        <Typography variant={'body2'} bold>
                          Website:{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {website ? (
                          <Link
                            variant={'body2'}
                            target={'__blank'}
                            href={ensureHttpsAndWww(website)}>
                            {website}
                          </Link>
                        ) : (
                          <Typography variant={'body2'}>{website}</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={12}>
                <VesselsMap
                  loading={!vesselsData?.length || isLoading}
                  showSpeedLegend
                  height={'50vh'}
                  zoom={0}
                  projection={'mercator'}
                  mapStyle={theme.mode === 'dark' ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE}
                  vessels={vesselsData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack>
              <TextWithTooltipIcon
                labelVariant={'h6'}
                label={'Fleet Development\n'}
                tooltipText={
                  'Please note the fleet development only counts vessels and operators covered by Esgian shipping'
                }
              />
            </Stack>
            <Stack direction="row" justifyContent={'space-between'}>
              <Box sx={{ width: '10em' }}>
                <DateRangeMobi
                  sx={{
                    inputProps: {
                      InputProps: {
                        sx: { fontSize: '0.875rem' }
                      },
                      variant: 'standard',
                      defaultValue: undefined,
                      value:
                        fleetDates.start || fleetDates.end
                          ? `${
                              fleetDates.start ? moment(fleetDates.start).format('MMM YYYY') : ''
                            } - ${fleetDates.end ? moment(fleetDates.end).format('MMM YYYY') : ''}`
                          : null
                    }
                  }}
                  onClose={({ value }) => {
                    if (value[0].isValid()) {
                      setFleetDates((prevState) => ({
                        ...prevState,
                        start: value[0].startOf('month')
                      }));
                    }
                    if (value[1].isValid()) {
                      setFleetDates((prevState) => ({
                        ...prevState,
                        end: value[1].isSameOrAfter(moment(), 'month')
                          ? moment()
                          : value[1].endOf('month')
                      }));
                    }
                  }}
                  dateFormat={'MMM YYYY'}
                  minStartDate={
                    isRoRo ? moment('07-2022', 'MM-YYYY') : moment('07-2023', 'MM-YYYY')
                  }
                  disableFuture
                  startDate={fleetDates.start}
                  endDate={fleetDates.end}
                  placeholder={'Period'}
                  dateWheels={'MMM YYYY'}
                />
              </Box>
              <Grid item>
                <Box sx={{ width: '10em' }}>
                  <Select
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                    displayEmpty
                    variant={'standard'}
                    labelId="operator-overview-filter"
                    inputProps={{ 'aria-label': 'Filter' }}
                    MenuProps={{ PaperProps: { style: { width: '10em' } } }}
                    sx={{ width: '10em' }}>
                    {filterOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Stack>
            <Divider />

            {isError ? (
              <NotFound show={true} header={'Error Loading Data'} text={'Please try again later'} />
            ) : (
              <>
                {selectedFilter === 'ageProfile' && (
                  <OperatorFleetAgeChart
                    operatorName={operatorShortName}
                    categories={categories}
                    loading={isLoading}
                    fleetAgeCeuData={fleetAgeCeuData}
                    totalCountVessel={totalCountVessel}
                    vesselsAdded={vesselsAdded}
                    vesselsRemoved={vesselsRemoved}
                    fileName={`${operatorShortName}-fleet-age`}
                    showForSingleSeries
                  />
                )}
                {selectedFilter === 'ceuCapacity' && (
                  <OperatorCEUChart
                    loading={isLoading}
                    CEUCapacityData={CEUCapacityData}
                    fileName={`${operatorShortName}-CEU-capacity`}
                    categories={categories}
                    vesselsAdded={vesselsAdded}
                    vesselsRemoved={vesselsRemoved}
                    showForSingleSeries
                  />
                )}
              </>
            )}
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <VesselsTable
          loadCapacityUnit={isRoRo ? 'CEU' : 'Deadweight'}
          fileName={`${operatorShortName}-vessel-list`}
          title={'Operator Vessels'}
          loading={isLoading}
          hideColumns={hideColumns}
          vessels={operatorVessels}
        />
      </Grid>
    </>
  );
}

OperatorOverviewSection.propTypes = {
  operatorData: PropTypes.object,
  vesselsData: PropTypes.arrayOf(PropTypes.object)
};

OperatorOverviewSection.defaultProps = {
  operatorData: null,
  vesselsData: []
};

export default OperatorOverviewSection;
