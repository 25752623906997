import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowBackIcon,
  ColorBox,
  Grid,
  Typography,
  Paper,
  Stack,
  Box,
  Skeleton,
  ErrorBoundaryPage,
  Modal,
  CircularProgress,
  ShareOutlinedIcon,
  Button
} from '@esgian/esgianui';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import VoyageGroupFilter from '@components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupFilter';
import { useVoyageGroups } from '../hooks/useVoyageGroups';
import SavedVoyageCard from '@components/Sections/VoyageAnalytics/VoyageGroupSection/SavedVoyageCard';
import VoyageGroupRouteMap from '@components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupRouteMap';
import { useSegment } from '@hooks';
import { scrollbarStyling } from '../../../utils/constants';
import {
  VoyageType,
  generateVoyageIdFromSavedVoyage,
  getVoyageLink
} from '@components/Sections/VoyageAnalytics/VoyageUtils';
import { useSelector } from 'react-redux';
import { getLookupLocations } from '@store/features';
import CreateVoyagePopup from '@components/Sections/VoyageAnalytics/VoyageGroupSection/CreateVoyagePopup';
import AddToVoyageGroupPopup from '@components/Sections/VoyageAnalytics/VoyageGroupSection/AddToVoyageGroupPopup';
import AddDestinationModal from '@components/Sections/VoyageAnalytics/VoyageGroupSection/AddDestinationModal';
import { routeColors } from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupRouteMap';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  getTimeDaysAgo,
  getTransitDays
} from '../../../components/Sections/VoyageAnalytics/VoyageUtils';
import { VESSEL_MAX_TRANSIT_TIME } from '../../../constants';
import Swal from 'sweetalert2';
import ButtonWithIcon from '../../../components/Common/ButtonWithIcon';

function VoyageGroupPage() {
  const params = useParams();
  const lookupLocations = useSelector(getLookupLocations);
  const voyageGroupId = params.voyageGroupId;

  const {
    completedVoyagesGroups,
    isLoadingVoyageGroups,
    voyageGroupWithVoyageDetails,
    deleteVoyageFromGroup,
    isDeletingVoyageFromGroup,
    isGettingSavedVoyagesDetails,
    isErrorGettingVoyageGroupWithVoyageDetails,
    isCreatingVoyageGroup,
    createVoyageGroup,
    updateVoyages,
    isUpdatingVoyages,
    moveVoyage,
    isMovingVoyage,
    loadingSavedVoyagesMessage,
    isSharedVoyageGroup,
    addVoyageGroupToShared,
    isAddingVoyageGroupToShared,
    saveSharedGroup
  } = useVoyageGroups({ voyageGroupId });

  const voyageGroupType = voyageGroupWithVoyageDetails?.type;
  const { uriExt } = useSegment();
  const [searchParams] = useSearchParams();
  const [hoveredVoyageId, setHoveredVoyageId] = useState();
  const [selectedVesselsIdList, setSelectedVesselsIdList] = useState([]);
  const [voyageToMoveToCompleted, setVoyageToMoveToCompleted] = useState();
  const [showCreateVoyageGroupPopup, setShowCreateVoyageGroupPopup] = useState(false);
  const [vesselsToAddDestination, setVesselsToAddDestination] = useState([]);
  const [mapRefreshCounter, setMapRefreshCounter] = useState(0);

  const routeOperatorColorMapRef = useRef();
  const deletingVoyageIdRef = useRef();
  const voyageGroupIdToAddDestinationRef = useRef();

  const ongoingGroupIdToMoveVoyageFromRef = useRef();

  const isFullScreen = searchParams.get('view') === 'map';

  const navigate = useNavigate();

  useEffect(() => {
    setMapRefreshCounter((prev) => prev + 1);
  }, [voyageGroupWithVoyageDetails]);

  useEffect(() => {
    if (!voyageGroupWithVoyageDetails) return;
    setSelectedVesselsIdList(
      voyageGroupWithVoyageDetails?.voyages.map((v) => generateVoyageIdFromSavedVoyage(v))
    );
  }, [voyageGroupWithVoyageDetails, isFullScreen]);

  useEffect(() => {
    if (!voyageGroupWithVoyageDetails || !voyageGroupWithVoyageDetails.voyages) return;
    const operators = [];
    voyageGroupWithVoyageDetails?.voyages.forEach(({ details }) => {
      if (!operators.includes(details?.operator)) {
        operators.push(details?.operator);
      }
    });
    const colorPerOperator = operators.reduce(
      (prev, operator, i) => ({ ...prev, [operator]: routeColors[i % routeColors.length] }),
      {}
    );
    routeOperatorColorMapRef.current = colorPerOperator;
  }, [voyageGroupWithVoyageDetails]);

  const savedVessels = useMemo(
    () =>
      voyageGroupWithVoyageDetails?.voyages.map((v) => ({
        name: v.details?.voyageReducedTransitDetails?.vesselName,
        id: generateVoyageIdFromSavedVoyage(v)
      })),
    [voyageGroupWithVoyageDetails?.voyages]
  );

  if (isErrorGettingVoyageGroupWithVoyageDetails) {
    return (
      <ErrorBoundaryPage
        header={'Error'}
        subHeader={`No data found with the voyage group id ${voyageGroupId}.`}
      />
    );
  }

  if (isFullScreen) {
    if (isGettingSavedVoyagesDetails || isDeletingVoyageFromGroup) {
      return <Skeleton variant="rectangular" width="100%" height="100%" />;
    }
    return (
      <Grid container sx={{ position: 'relative' }} spacing={1}>
        <VoyageGroupFilter
          multiSelectOptions={savedVessels}
          selectedVesselsId={selectedVesselsIdList}
          onSelectVessels={setSelectedVesselsIdList}
        />
        <VoyageGroupRouteMap
          voyageGroupWithVoyageDetails={voyageGroupWithVoyageDetails}
          selectedVesselsIdList={selectedVesselsIdList}
          routeOperatorColorMap={routeOperatorColorMapRef.current}
          key={mapRefreshCounter}
        />
      </Grid>
    );
  }

  if (isGettingSavedVoyagesDetails) {
    return <VoyageGroupPageLoader loadingSavedVoyagesMessage={loadingSavedVoyagesMessage} />;
  }

  const shouldShowVoyageList =
    voyageGroupWithVoyageDetails?.voyages &&
    voyageGroupWithVoyageDetails.voyages.length > 0 &&
    !isGettingSavedVoyagesDetails &&
    !isFullScreen;

  const isOngoingVoyage = voyageGroupType === VoyageType.ONGOING;

  const goBackUrl = `${uriExt}/${isOngoingVoyage ? 'ongoing' : 'completed'}-voyages`;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <ColorBox
                onClick={() =>
                  navigate(goBackUrl, {
                    relative: true
                  })
                }>
                <ArrowBackIcon fontSize={'small'} />
              </ColorBox>
              <Box sx={{ marginLeft: 1 }}>
                {isGettingSavedVoyagesDetails ? (
                  <Skeleton variant={'text'} height={25} width="300px" />
                ) : (
                  <Typography variant={'h6'}>{voyageGroupWithVoyageDetails?.name ?? ''}</Typography>
                )}
                {isGettingSavedVoyagesDetails ? (
                  <Skeleton variant={'text'} height={25} width="300px" />
                ) : (
                  <Typography variant={'body2'}>
                    {isOngoingVoyage ? 'Ongoing' : 'Completed'} Voyages:{' '}
                    {voyageGroupWithVoyageDetails?.voyages?.length ?? 0}
                  </Typography>
                )}
              </Box>
            </Box>
            {!isSharedVoyageGroup && (
              <Box>
                <ButtonWithIcon
                  isLoading={isAddingVoyageGroupToShared}
                  startIcon={<ShareOutlinedIcon />}
                  onClick={async () => {
                    try {
                      const { id } = await addVoyageGroupToShared({
                        voyageGroupToAdd: voyageGroupWithVoyageDetails
                      });
                      const linkToCopy = `${window.location.origin}${window.location.pathname
                        .split('/')
                        .slice(0, -1)
                        .join('/')}/${id}`;
                      await navigator.clipboard.writeText(linkToCopy);
                      Swal.fire({
                        icon: 'success',
                        titleText: 'Link copied',
                        text: 'People with the link can view the group.',
                        showConfirmButton: false
                      });
                    } catch (error) {
                      console.error('Sharing error:', error);
                      Swal.fire({
                        icon: 'error',
                        titleText: 'Unable to share',
                        text: 'Shareable link for this group could not be generated.',
                        showConfirmButton: false
                      });
                    }
                  }}
                  variant="outlined"
                  label="Share"
                />
              </Box>
            )}
            {isSharedVoyageGroup && (
              <Button
                onClick={async () => {
                  try {
                    const savedSharedGroup = await saveSharedGroup({
                      sharedVoyageGroup: voyageGroupWithVoyageDetails
                    });
                    Swal.fire({
                      icon: 'success',
                      titleText: 'Shared voyage group saved',
                      text: 'You are now viewing this as your own group.',
                      showConfirmButton: false
                    });
                    navigate(`${uriExt}/voyage-group/${savedSharedGroup.id}`, {
                      relative: true
                    });
                  } catch (error) {
                    console.error('Saving error:', error);
                    Swal.fire({
                      icon: 'error',
                      titleText: 'Unable to save',
                      text: error.message ?? 'Shared voyage group could not be saved.',
                      showConfirmButton: false
                    });
                  }
                }}
                style={{ width: '100px' }}>
                Save
              </Button>
            )}
          </Box>
        </Grid>

        <Grid item xs={3} sx={{ height: '100%' }}>
          <Stack direction="column" height="90vh">
            <Paper sx={{ p: 2 }}>
              <Typography variant={'h6'}>Voyage List</Typography>
            </Paper>
            {shouldShowVoyageList && (
              <Box overflow="auto" sx={scrollbarStyling}>
                {voyageGroupWithVoyageDetails.voyages.map((singleSavedVoyageWithDetails) => {
                  const voyageId = generateVoyageIdFromSavedVoyage(singleSavedVoyageWithDetails);

                  let departureDate = moment(
                    singleSavedVoyageWithDetails?.details?.voyageReducedTransitDetails?.startDate
                  );
                  if (
                    isOngoingVoyage &&
                    getTransitDays(departureDate, moment()) > VESSEL_MAX_TRANSIT_TIME
                  ) {
                    departureDate = moment(getTimeDaysAgo(VESSEL_MAX_TRANSIT_TIME));
                  }
                  return (
                    <Box
                      key={voyageId}
                      onMouseEnter={() => {
                        setHoveredVoyageId(voyageId);
                      }}
                      onMouseLeave={() => {
                        setHoveredVoyageId(undefined);
                      }}
                      sx={{ cursor: 'pointer' }}>
                      <SavedVoyageCard
                        singleSavedVoyageWithDetails={singleSavedVoyageWithDetails}
                        isHovered={hoveredVoyageId === voyageId}
                        onDelete={async () => {
                          deletingVoyageIdRef.current = voyageId;
                          await deleteVoyageFromGroup({
                            voyageGroupId,
                            savedVoyageIdToDelete: voyageId
                          });
                          deletingVoyageIdRef.current = undefined;
                        }}
                        isDeletingVoyageFromGroup={
                          isDeletingVoyageFromGroup && deletingVoyageIdRef?.current === voyageId
                        }
                        voyageLink={getVoyageLink(
                          uriExt,
                          {
                            ...singleSavedVoyageWithDetails.details,
                            imo: singleSavedVoyageWithDetails.imo,
                            fromPortId: singleSavedVoyageWithDetails.fromPortId,
                            departureDate: departureDate.toISOString(),
                            arrivalDate: singleSavedVoyageWithDetails.arrivalDate,
                            toPortId: singleSavedVoyageWithDetails.toPortId
                          },
                          voyageGroupType
                        )}
                        voyageGroupType={voyageGroupType}
                        lookupLocations={lookupLocations}
                        onMoveToCompletedGroup={() => {
                          setVoyageToMoveToCompleted(singleSavedVoyageWithDetails);
                          ongoingGroupIdToMoveVoyageFromRef.current = voyageGroupId;
                        }}
                        onAddArrivalPort={() => {
                          setVesselsToAddDestination([
                            {
                              ...singleSavedVoyageWithDetails,
                              vesselName:
                                singleSavedVoyageWithDetails.details?.voyageOverview?.vesselName
                            }
                          ]);
                          voyageGroupIdToAddDestinationRef.current = voyageGroupId;
                        }}
                        departureDate={departureDate}
                        isSharedVoyageGroup={isSharedVoyageGroup}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}
          </Stack>
        </Grid>
        <Grid item xs={9} sx={{ height: '100%' }}>
          <Stack direction="column" spacing={1}>
            {isGettingSavedVoyagesDetails ? (
              <Grid
                item
                xs={12}
                style={{
                  minHeight: '800px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative'
                }}>
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    top: 'calc(50% - 50px)',
                    left: 'calc(50% - 50px)'
                  }}
                  size={100}
                />
              </Grid>
            ) : (
              <VoyageGroupRouteMap
                voyageGroupWithVoyageDetails={voyageGroupWithVoyageDetails}
                selectedVesselsIdList={selectedVesselsIdList}
                hoveredVoyageId={hoveredVoyageId}
                key={mapRefreshCounter}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      {voyageToMoveToCompleted && (
        <Modal open handleClose={() => setVoyageToMoveToCompleted(undefined)} size="xs">
          <AddToVoyageGroupPopup
            savedGroups={completedVoyagesGroups}
            isSavingVoyageToGroup={isMovingVoyage}
            isLoadingVoyageGroups={isLoadingVoyageGroups}
            onAddVoyageToGroup={async (groupId) => {
              try {
                await moveVoyage({
                  currentGroupId: ongoingGroupIdToMoveVoyageFromRef.current,
                  targetGroupId: groupId,
                  voyageToMove: voyageToMoveToCompleted
                });
                setVoyageToMoveToCompleted(undefined);
                ongoingGroupIdToMoveVoyageFromRef.current = undefined;
              } catch (error) {
                console.error(error.message ?? 'Unable to move');
              }
            }}
            onCreateANewGroup={() => {
              setShowCreateVoyageGroupPopup(true);
            }}
            width="100%"
            backgroundColor="inherit"
          />
        </Modal>
      )}
      {showCreateVoyageGroupPopup && (
        <CreateVoyagePopup
          open
          setModalOpen={setShowCreateVoyageGroupPopup}
          existingGroups={completedVoyagesGroups}
          isSubmitDisabled={isCreatingVoyageGroup}
          onSubmit={(newVoyageGroupName, clearInput) => {
            createVoyageGroup({
              newVoyageGroupName,
              type: VoyageType.COMPLETED
            }).then(() => {
              clearInput?.();
              setShowCreateVoyageGroupPopup(false);
            });
          }}
        />
      )}
      {vesselsToAddDestination.length > 0 && (
        <AddDestinationModal
          open
          onClose={() => setVesselsToAddDestination([])}
          vesselsToAddDestination={vesselsToAddDestination}
          isAddingDestination={isUpdatingVoyages}
          onUpdate={async (updatedVoyages) => {
            await updateVoyages({
              targetGroupId: voyageGroupIdToAddDestinationRef.current,
              updatedVoyages
            });
            voyageGroupIdToAddDestinationRef.current = undefined;
            setVesselsToAddDestination([]);
          }}
        />
      )}
    </>
  );
}

VoyageGroupPage.defaultProps = {};

export default VoyageGroupPage;

const VoyageGroupPageLoader = ({ loadingSavedVoyagesMessage }) => (
  <Stack
    container
    sx={{
      position: 'relative',
      display: 'grid',
      width: '100vw',
      height: '600px',
      alignItems: 'center'
    }}
    spacing={1}>
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px'
      }}>
      <CircularProgress size={100} />
      <Typography>{loadingSavedVoyagesMessage}</Typography>
    </Box>
  </Stack>
);

VoyageGroupPageLoader.propTypes = {
  loadingSavedVoyagesMessage: PropTypes.string
};
