import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '../TableWithSummary/TableWithSummary';
import { TextWithTooltipIcon } from '@components';
import { ESTIMATED_AVG_EU_ETS_COST_TEXT } from '@constants/tooltips';
import { getValueUptoOneDecimalPlace } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';
import { formatNumberReadable } from '@helpers';

const OverallVoyageEstimatedEtsTable = ({ overallVoyageEuEtsAllowanceSummary, isLoading }) => {
  const [summaryValue, setSummaryValue] = useState({
    voyageEtsCost: '--',
    estimatedEtsCostPerTonCargo: '--'
  });

  const columns = useMemo(
    () => [
      { name: 'items', label: 'Items' },
      { name: 'amount', label: 'Amount' }
    ],
    []
  );

  const data = useMemo(() => {
    if (!overallVoyageEuEtsAllowanceSummary) return [];

    return [
      {
        items: { value: 'Average Cargo in the voyage  (T)' },
        amount: {
          value: formatNumberReadable(
            overallVoyageEuEtsAllowanceSummary.avgCargoWeightTn?.toFixed(0)
          )
        }
      },
      {
        items: { value: 'Total EU ETS cost for voyage (€)' },
        amount: {
          value: formatNumberReadable(overallVoyageEuEtsAllowanceSummary.voyageEtsCost?.toFixed(0))
        }
      },
      {
        items: { value: 'Total EU ETS Allowances for the voyage (T)' },
        amount: {
          value: formatNumberReadable(overallVoyageEuEtsAllowanceSummary.totalCo2Tn?.toFixed(0))
        }
      },
      {
        items: {
          value: 'EU ETS cost per tonne cargo (€)',
          tooltip: ESTIMATED_AVG_EU_ETS_COST_TEXT
        },
        amount: {
          value: Number(overallVoyageEuEtsAllowanceSummary.estimatedEtsCostPerTonCargo).toFixed(1)
        }
      },
      {
        items: { value: 'EU Allowances for the voyage' },
        amount: {
          value: formatNumberReadable(
            overallVoyageEuEtsAllowanceSummary.totalEuEtsAllowanceTn?.toFixed(1)
          )
        }
      }
    ];
  }, [overallVoyageEuEtsAllowanceSummary]);

  useEffect(() => {
    if (!overallVoyageEuEtsAllowanceSummary) return;
    setSummaryValue({
      voyageEtsCost: overallVoyageEuEtsAllowanceSummary.voyageEtsCost,
      estimatedEtsCostPerTonCargo: overallVoyageEuEtsAllowanceSummary.estimatedEtsCostPerTonCargo
    });
  }, [overallVoyageEuEtsAllowanceSummary]);

  const summary = useMemo(
    () => [
      {
        label: 'EU ETS cost for the overall voyage (€)',
        value: formatNumberReadable(Number(summaryValue.voyageEtsCost).toFixed(0))
      },
      {
        label: (
          <TextWithTooltipIcon
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '0.1em'
            }}
            label="EU ETS cost per tonne cargo (€)"
            tooltipText={ESTIMATED_AVG_EU_ETS_COST_TEXT}
          />
        ),
        value: getValueUptoOneDecimalPlace(summaryValue.estimatedEtsCostPerTonCargo)
      }
    ],
    [summaryValue]
  );

  return (
    <TableWithSummary
      title="Overall voyage"
      columns={columns}
      data={data}
      summary={summary}
      isLoading={isLoading}
    />
  );
};

OverallVoyageEstimatedEtsTable.propTypes = {
  overallVoyageEuEtsAllowanceSummary: PropTypes.object,
  isLoading: PropTypes.bool
};

export default OverallVoyageEstimatedEtsTable;
