import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  CloseIcon,
  IconButton,
  Divider,
  DoubleArrowRightIcon,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import VesselDetails from '@components/Maps/VesselsMap/AdvancedTooltip/VesselDetails';
import { useTheme } from '@hooks/useTheme';
function sortVessels(a, b) {
  if (a.vesselName < b.vesselName) {
    return -1;
  }
  if (a.vesselName > b.vesselName) {
    return 1;
  }
  return 0;
}
function AdvancedTooltip({
  selectedVessel,
  setSelectedVessel,
  handleZoomReset,
  showVesselList,
  vessels,
  mapHeight,
  map,
  loading
}) {
  const [vesselList, setVesselList] = useState([]);
  const [query, setQuery] = useState('');
  let navigate = useNavigate();
  let { customScrollbar } = useTheme();

  const routeChange = (path) => {
    navigate(path);
  };

  const handleVesselSelect = (vessel) => {
    map.flyTo({ center: [vessel.long, vessel.lat], zoom: 10, duration: 5000 });
    setSelectedVessel(vessel);
  };

  useEffect(() => {
    if (query !== '') {
      setVesselList(
        vessels
          .filter(({ vesselName }) => {
            return vesselName?.toLowerCase()?.includes(query?.toLowerCase());
          })
          .sort(sortVessels)
      );
    } else {
      setVesselList(vessels.sort(sortVessels));
    }
  }, [vessels, query]);

  const { vesselName } = selectedVessel || {};

  return (
    <Box
      sx={{
        boxShadow: 'rgb(0 0 0 / 25%) 0px 0px 20px 0px',
        position: 'absolute',
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
        zIndex: 1,
        top: '10px',
        bottom: '10px',
        left: showVesselList ? '10px' : selectedVessel ? '10px' : '-26%',
        width: '25%',
        transition: 'left 1s',
        height: 'calc(100% - 20px)',
        display: 'flex',
        maxHeight: `calc(${mapHeight} - 20px)`,
        backgroundColor: ({ palette }) => palette.background.paper
      }}>
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px',
            alignItems: 'center',
            background: ({ palette }) =>
              palette.mode === 'light' ? palette.primary.main : palette.primary.dark,
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
            width: '100%',
            boxShadow: selectedVessel ? '0px 3px 3px 0px rgb(0 0 0 / 25%)' : ''
          }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
              <Typography color={'#fff'} bold variant={'h6'}>
                {selectedVessel ? vesselName : 'Vessels'}
              </Typography>
              <IconButton
                sx={{ color: '#fff' }}
                onClick={() => {
                  setSelectedVessel(null);
                  handleZoomReset();
                }}>
                <CloseIcon color={'inherit'} />
              </IconButton>
            </Stack>
          </Grid>
        </Box>
        <div
          style={{
            display: 'flex',
            padding: '0px'
          }}>
          {!!(!selectedVessel && (loading || vessels.length)) && (
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  p: 2,
                  zIndex: 10,
                  boxShadow: 'rgb(0 0 0 / 25%) 0px 3px 3px 0px'
                }}>
                <TextField
                  size={'small'}
                  label={!loading ? '' : <CircularProgress size={25} />}
                  value={query}
                  onChange={({ target }) => setQuery(target.value)}
                  placeholder={'Search...'}
                  fullWidth
                />
              </Grid>

              {(!!vesselList?.length || loading) && (
                <Grid item xs={12} sx={{ pt: '0px !important' }}>
                  <List
                    sx={{
                      ...customScrollbar,
                      width: '100%',
                      bgColor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: `calc(${mapHeight} - 60px - 50px - 45px)`,
                      '& ul': { padding: 0 }
                    }}>
                    {loading &&
                      Array.apply(null, { length: 12 }).map((e, i) => (
                        <React.Fragment key={`skeleton-${i}`}>
                          <ListItem
                            sx={{
                              backgroundColor: ({ palette }) =>
                                i % 2 === 0 ? palette.background.paper : palette.background.default
                            }}>
                            <ListItemText>
                              <Skeleton width={'100%'} />
                            </ListItemText>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                    {!loading &&
                      vesselList?.map((vessel, i) => (
                        <React.Fragment key={`${vessel.imo}-${i}`}>
                          <ListItem
                            sx={{
                              backgroundColor: ({ palette }) =>
                                i % 2 === 0 ? palette.background.paper : palette.background.default
                            }}
                            onClick={() => handleVesselSelect(vessel)}
                            button
                            secondaryAction={<DoubleArrowRightIcon />}>
                            <ListItemText
                              primaryTypographyProps={{ variant: 'body2' }}
                              primary={vessel?.vesselName}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                  </List>
                </Grid>
              )}
              {!vesselList?.length && !loading && (
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Typography variant={'body2'}>No matching search results</Typography>
                </Grid>
              )}
            </Grid>
          )}
          {!loading && !vessels.length && (
            <Grid item container sx={{ p: 2 }}>
              <Typography variant={'body2'}>No vessels in region</Typography>
            </Grid>
          )}
          {selectedVessel && (
            <VesselDetails
              mapHeight={mapHeight}
              selectedVessel={selectedVessel}
              routeChange={routeChange}
            />
          )}
        </div>
      </div>
    </Box>
  );
}

AdvancedTooltip.propTypes = {
  selectedVessel: PropTypes.object,
  setSelectedVessel: PropTypes.func.isRequired,
  themeMode: PropTypes.bool,
  handleZoomReset: PropTypes.func.isRequired,
  showVesselList: PropTypes.bool,
  vessels: PropTypes.arrayOf(PropTypes.object),
  mapHeight: PropTypes.string.isRequired,
  map: PropTypes.object,
  loading: PropTypes.bool
};

AdvancedTooltip.defaultProps = {
  selectedVessel: null,
  themeMode: false,
  showVesselList: false,
  map: null,
  loading: false,
  vessels: []
};

export default AdvancedTooltip;
