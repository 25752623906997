import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '../TableWithSummary/TableWithSummary';
import { formatNumberReadable } from '@helpers';
import { getValueUptoOneDecimalPlace } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';

const MyCargoExpandedEstimatedETSTable = ({ myCargoEuEtsAllowanceCost, isLoading }) => {
  const columns = [
    { name: 'voyageLeg', label: 'Voyage Leg' },
    { name: 'origin', label: 'Origin' },
    { name: 'destination', label: 'Destination' },
    {
      name: 'totalEstimatedCargo',
      label: 'Total Cargo (T)',
      tooltip: 'Estimated cargo weight as per Esgian estimation model'
    },
    { name: 'myCargo', label: 'My Cargo (MT)' },
    {
      name: 'shareOfMyCargo',
      label: 'Share of My Cargo',
      tooltip: 'My cargo weight/ Estimated total cargo weight onboard ( % )'
    },
    { name: 'estimatedEtsCostEuro', label: 'EU ETS Cost (€)' }
  ];

  const createTableRow = (d, index) => ({
    voyageLeg: { value: `#${index + 1}` },
    origin: { value: d.fromPortName },
    destination: { value: d.toPortName },
    totalEstimatedCargo: { value: formatNumberReadable(d.cargoWeightTn?.toFixed(0)) },
    myCargo: { value: formatNumberReadable(d.myCargoWeightTn?.toFixed(1)) },
    shareOfMyCargo: { value: `${getValueUptoOneDecimalPlace(d.myCargoShare)}%` },
    estimatedEtsCostEuro: { value: formatNumberReadable(d.myCargoEstimatedEtsCost?.toFixed(0)) }
  });

  const data = myCargoEuEtsAllowanceCost
    ? myCargoEuEtsAllowanceCost.voyageLegMyCargoEuEtsAllowanceCosts.map(createTableRow)
    : [];

  const totalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    totalEstimatedCargo: { value: '-' },
    myCargo: { value: '-' },
    shareOfMyCargo: { value: '-' },
    estimatedEtsCostEuro: {
      value: myCargoEuEtsAllowanceCost
        ? formatNumberReadable(
            myCargoEuEtsAllowanceCost?.totalMyCargoEstimatedEuEtsCost?.toFixed(0)
          )
        : '-'
    }
  };

  return (
    <TableWithSummary
      title="EU ETS Cost for My Cargo"
      columns={columns}
      data={[...data, totalRow]}
      isLoading={isLoading}
    />
  );
};

MyCargoExpandedEstimatedETSTable.propTypes = {
  myCargoEuEtsAllowanceCost: PropTypes.object,
  isLoading: PropTypes.bool
};

export default MyCargoExpandedEstimatedETSTable;
