import React, { useEffect, useMemo, useState } from 'react';
import {
  ArrowBackIcon,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  InfoOutlineIcon,
  Skeleton,
  Stack,
  ToolTip,
  Typography
} from '@esgian/esgianui';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getVoyageProfileFilters } from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';
import { VoyageSummaryFilterKeys } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryConstants';
import { uesGetVoyageDetails } from '../hooks/useGetVoyageDetails';
import VoyageSummaryFilter from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryFilter';
import {
  VoyageSummaryOverviewFloatingWindow,
  VoyageSummaryShortestRouteOverviewFloatingWindow,
  VoyageSummarySpeedIndicatorFloatingWindow
} from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryOverview';
import VoyageSummaryRouteMap from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap';
import VoyageSummarySpeed from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummarySpeed';
import VoyageSummaryDraught from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryDraught';
import { updateVoyageProfileFilters } from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';
import VoyageDetails from './VoyageDetailsDrawer';
import VoyageGroupsPopup from '@components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupsPopup';
import {
  AisDataDescription,
  AisDataStatusColor,
  getAisStatus
} from '../CompletedVoyageProfilePage/CompletedVoyageProfilePage';
import { getThemeMode } from '@store/features';

const OngoingVoyageProfilePage = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const { voyageDetails, isLoadingVoyageDetails } = uesGetVoyageDetails();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [showVoyageDetails, setShowVoyageDetails] = useState(false);
  const navigate = useNavigate();
  const {
    mapOptions: {
      shortestPath: showShortestPath,
      speed: showSpeed,
      draughtGrade: showDraughtGrade,
      voyageInfo: showVoyageInfo,
      draught: showDraught
    }
  } = useSelector(getVoyageProfileFilters);
  const { voyageOverview, voyagePortCalls, voyageTransitDetails, shortestPath, latestPortCall } =
    voyageDetails ?? {};

  useEffect(() => {
    dispatch(updateVoyageProfileFilters({ mapOptions: { fullscreen: true } }));
  }, []);

  const selectedPeriodFilter = useMemo(() => {
    let startDate = searchParams.get(VoyageSummaryFilterKeys.START_DATE);
    let endDate = searchParams.get(VoyageSummaryFilterKeys.END_DATE);
    startDate = startDate ? +startDate : null;
    endDate = endDate ? +endDate : null;
    return { selectedPeriodStart: startDate, selectedPeriodEnd: endDate };
  }, [searchParams]);

  const {
    aisStatus = 0,
    aisColor,
    aisDataDescription
  } = useMemo(() => {
    if (isLoadingVoyageDetails) return {};
    const {
      voyageTransitDetails: { aisDensity }
    } = voyageDetails;
    const aisStatus = getAisStatus(aisDensity ?? 0);
    return {
      aisStatus: aisStatus,
      aisColor: AisDataStatusColor[aisStatus],
      aisDataDescription: AisDataDescription[aisStatus]
    };
  }, [voyageDetails]);

  const filteredTransitDetails = useMemo(() => {
    const { selectedPeriodStart, selectedPeriodEnd } = selectedPeriodFilter;
    let start = moment(selectedPeriodEnd);
    let end = moment(selectedPeriodEnd);
    return {
      ...voyageTransitDetails,
      transitDetails: voyageTransitDetails?.transitDetails?.filter(({ timeStamp }) => {
        const currTimestamp = moment(timeStamp);
        const isMatchStartTimestampCriteria =
          !selectedPeriodStart || currTimestamp.isSameOrAfter(start);
        const isMatchEndTimestampCriteria = !selectedPeriodEnd || currTimestamp.isSameOrBefore(end);
        return isMatchStartTimestampCriteria && isMatchEndTimestampCriteria;
      })
    };
  }, [voyageTransitDetails, selectedPeriodFilter]);

  const shouldShowFullscreenMap = !showSpeed && !showDraught;
  const shouldShowBoth = showSpeed && showDraught;

  if (isLoadingVoyageDetails) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress
          sx={{
            position: 'absolute',
            top: 'calc(50% - 50px)',
            left: 'calc(50% - 50px)'
          }}
          size={100}
        />
      </Grid>
    );
  }

  const isOngoingVoyage = location.pathname.includes('ongoing-voyages');

  return (
    <Grid container xs={12} spacing={1}>
      <Box height="100px" width="100%" p={2}>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2}>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  background: '#80DFEB33',
                  borderRadius: '0.5rem',
                  height: 'fit-content'
                }}>
                <ArrowBackIcon fontSize={'small'} />
              </IconButton>
              <Stack spacing={1}>
                <Typography variant={'h5'}>
                  {isLoadingVoyageDetails ? (
                    <Skeleton width={'20vw'} height={'1em'} variant={'rectangular'} />
                  ) : (
                    `${voyageOverview?.vesselName}`
                  )}
                </Typography>
                <Typography id={`voyage-start-port`}>
                  {isLoadingVoyageDetails ? (
                    <Skeleton width={'20vw'} />
                  ) : (
                    `Latest port call: ${latestPortCall?.portName ?? '-'}`
                  )}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <Button
                variant="text"
                sx={{
                  height: 'fit-content',
                  color: 'text.primary',
                  textTransform: 'none'
                }}
                startIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none">
                    <circle cx="4" cy="4" r="4" fill={aisColor} />
                  </svg>
                }
                endIcon={
                  <ToolTip title={aisDataDescription}>
                    <InfoOutlineIcon sx={{ fontSize: 16 }} />
                  </ToolTip>
                }>
                <Typography variant="body2">{`AIS: ${aisStatus}`}</Typography>
              </Button>
              <VoyageGroupsPopup mode="profile" />
            </Stack>
          </Stack>
        </Grid>
      </Box>
      <Grid container sx={{ position: 'relative', padding: '20px' }} spacing={1}>
        <VoyageSummaryFilter
          voyageTransitDetails={voyageTransitDetails}
          voyagePortCalls={voyagePortCalls}
          isOngoingVoyage={isOngoingVoyage}
          onShowVoyageDetails={() => setShowVoyageDetails(true)}
        />
        <Stack position="absolute" left="2rem" top="7rem" spacing={1}>
          {showVoyageInfo && (
            <VoyageSummaryOverviewFloatingWindow
              voyageTransitDetails={voyageTransitDetails}
              summaryOverview={voyageOverview}
              voyagePortCalls={voyagePortCalls}
              isOngoingVoyage={isOngoingVoyage}
            />
          )}
          {showShortestPath && !isOngoingVoyage && (
            <VoyageSummaryShortestRouteOverviewFloatingWindow
              shortestPathDistanceNm={shortestPath?.shortestPathDistanceNm}
              voyageTransitDetails={voyageTransitDetails}
            />
          )}
          {showDraughtGrade && <VoyageSummarySpeedIndicatorFloatingWindow />}
        </Stack>
        <VoyageSummaryRouteMap
          voyageTransitDetails={voyageTransitDetails}
          shortestPath={shortestPath ?? {}}
          voyagePortCalls={voyagePortCalls}
          shouldShowFullscreenMap={shouldShowFullscreenMap}
          isOngoingVoyage={isOngoingVoyage}
          key={themeMode}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {showSpeed && (
            <Grid item xs={12} md={shouldShowBoth ? 6 : 12} alignSelf={'center'}>
              <VoyageSummarySpeed
                isFullScreen
                voyageTransitDetails={filteredTransitDetails}
                sx={{ height: '30vh' }}
              />
            </Grid>
          )}
          {showDraught && (
            <Grid item xs={12} md={shouldShowBoth ? 6 : 12} alignSelf={'center'}>
              <VoyageSummaryDraught
                voyageTransitDetails={filteredTransitDetails}
                isFullScreen
                sx={{ height: '30vh' }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={showVoyageDetails}
        variant={'persistent'}
        onClose={() => {
          setShowVoyageDetails(false);
        }}>
        <VoyageDetails
          onClose={() => setShowVoyageDetails(false)}
          voyageTransitDetails={voyageTransitDetails}
          summaryOverview={voyageOverview}
          voyagePortCalls={voyagePortCalls}
          filteredTransitDetails={filteredTransitDetails}
          latestPortCall={latestPortCall}
        />
      </Drawer>
    </Grid>
  );
};

export default OngoingVoyageProfilePage;
