import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '../TableWithSummary/TableWithSummary';
import { formatNumberReadable } from '@helpers';

const MyCargoCo2eEmissionTable = ({ myCargoToc, emissionSummary, isLoading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      createDataRow('My Cargo (MT)', emissionSummary.myCargoTn?.toFixed(1), false),
      createDataRow(
        'TOC GHG Emission for my cargo (kg)',
        emissionSummary.tocGhgEmissionKg?.toFixed(0),
        true,
        'TOC ( Transport Operation Category: A TOC is a group of transport operations that share similar characteristics). TOC GHG Emission corresponds to GHG emission from transport operations that include laden and ballast voyages.'
      ),
      createDataRow(
        'HOC GHG Emission for my cargo (kg)',
        emissionSummary.hocGhgEmissionKg?.toFixed(0),
        true,
        'HOC ( Hub Operation Category: A HOC is a group of hub operations that share similar characteristics). HOC GHG Emission corresponds to GHG emission from equipment at port during loading and discharging operations at ports.'
      ),
      createDataRow(
        'GHG Emission for Ballast voyage for my cargo (kg)',
        myCargoToc.ballast?.toFixed(0),
        true,
        'The ballast voyage emissions are based on the assumption that there was a ballast voyage preceding the voyage. The emission is assumed to be 92% of the average emissions of a voyage leg in the entire voyage. For RORO vessels, it is assumed that there are no ballast voyages preceding the voyage.'
      ),
      createDataRow(
        'Total GHG Emission for my cargo (kg)',
        myCargoToc.totalGhgEmissionOverallPlusBallastKg?.toFixed(0),
        true,
        'Total GHG Emission indicates the sum of TOC GHG emission, HOC GHG emission.'
      )
    ]);
  }, [emissionSummary, myCargoToc]);

  const columns = [
    { name: 'items', label: 'Items' },
    { name: 'amount', label: 'Amount' }
  ];

  const summary = [
    {
      label: 'Total GHG Emission for My Cargo (kg)',
      value: formatNumberReadable(emissionSummary.totalGhgEmission?.toFixed(0))
    }
  ];

  return (
    <TableWithSummary
      title="My Cargo"
      columns={columns}
      data={data}
      summary={summary}
      isLoading={isLoading}
    />
  );
};

const createDataRow = (label, value, hasTooltip = false, tooltipText = '') => ({
  items: {
    value: label,
    ...(hasTooltip && { tooltip: tooltipText })
  },
  amount: {
    value: formatNumberReadable(value),
    isEditable: false
  }
});

MyCargoCo2eEmissionTable.propTypes = {
  myCargoToc: PropTypes.object,
  emissionSummary: PropTypes.object,
  isLoading: PropTypes.bool
};

export default MyCargoCo2eEmissionTable;
