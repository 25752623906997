import { ApexChart, Paper, Skeleton } from '@esgian/esgianui';
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@hooks/useTheme';
import moment from 'moment';

export const emissionAndEtsChartColors = ['#80DFEB', '#DD53FF', '#FFD465', '#0E9EFF'];

export const graphDataLimit = 100;

function FuelConsumptionDailyChart({ fuelDetails, isLoading }) {
  const { theme } = useTheme();
  const categories = [];
  const series = [];
  if (fuelDetails) {
    const { dailyFuelConsumptions, fuelTypeIdsFuelNames } = fuelDetails;
    categories.push(...dailyFuelConsumptions.map((f) => f.dayDate));

    const seriesDataPerFuelTypeIdPerDay = {};
    dailyFuelConsumptions.forEach(({ dayDate, fuelConsumption }, i) => {
      if (i >= graphDataLimit) {
        return;
      }
      Object.entries(fuelConsumption).forEach(([fuelTypeId, consumption]) => {
        if (!seriesDataPerFuelTypeIdPerDay[fuelTypeId]) {
          seriesDataPerFuelTypeIdPerDay[fuelTypeId] = {
            [dayDate]: consumption
          };
          return;
        }
        seriesDataPerFuelTypeIdPerDay[fuelTypeId][dayDate] = consumption;
      });
    });

    Object.entries(seriesDataPerFuelTypeIdPerDay).forEach(
      ([fuelTypeId, consumptionPerDay], index) => {
        series.push({
          name: fuelTypeIdsFuelNames[fuelTypeId],
          data: categories.map((t) => consumptionPerDay[t] || null),
          color: emissionAndEtsChartColors[index]
        });
      }
    );
  }

  const options = {
    title: {
      text: 'Fuel consumption over time',
      style: {
        fontSize: '20px',
        fontWeight: 'normal',
        fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
      }
    },
    chart: {
      stacked: true,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      background: theme.palette.charts.background
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2
      }
    },
    xaxis: {
      categories: categories.slice(0, graphDataLimit),
      type: 'datetime',
      tickAmount: categories.length,
      labels: {
        formatter: function (val) {
          return moment(val).format('YYYY-MM-DD');
        },
        rotate: -45,
        rotateAlways: true
      }
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      min: 0,
      title: {
        text: 'Tonnes',
        style: {
          fontWeight: 900,
          fontSize: '11px',
          fontFamily: '"Noto Sans", Roboto, helvetica, Arial, sans-serif'
        }
      },
      labels: {
        formatter: function (val) {
          return val === null ? null : val.toFixed(2);
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: -10
    },
    theme: {
      mode: theme.mode
    },
    dataLabels: {
      enabled: false
    }
  };

  if (isLoading) {
    return <Skeleton height="100%" variant="rectangular" />;
  }

  return (
    <Paper sx={{ padding: 2, height: '100%' }}>
      {!isLoading && <ApexChart options={options} type="bar" data={series} />}
    </Paper>
  );
}

FuelConsumptionDailyChart.propTypes = {
  fuelDetails: PropTypes.object,
  isLoading: PropTypes.bool
};

export default FuelConsumptionDailyChart;
