import {
  fetchSegmentLookups,
  setCommercialCategoryType,
  setInit,
  setOperatorType,
  setSegment
} from '@store/features';
import { APP_NAME, COMMERCIAL_CATEGORY_TYPE, OPERATOR_TYPE, SEGMENT } from '@constants';
import {
  resetActivityMapFilters,
  updateActivityMapFilters
} from '@store/features/filters/CommercialAnalyticsPage/ActivityMapSlice/ActivityMapSlice';
import {
  resetPortActivityFilters,
  updatePortActivityFilters
} from '@store/features/filters/CommercialAnalyticsPage/PortActivitySlice/PortActivitySlice';
import {
  resetServiceLevelFilters,
  updateServiceLevelFilters
} from '@store/features/filters/CommercialAnalyticsPage/ServiceLevelSlice/ServiceLevelSlice';
import {
  resetOperatorPerformanceFilters,
  updateOperatorPerformanceFilters
} from '@store/features/filters/CommercialAnalyticsPage/OperatorPerformanceSlice/OperatorPerformanceSlice';
import {
  resetOprCo2EmissionsFilters,
  resetOprFleetCiiFilters,
  updateOprCo2EmissionsFilters,
  updateOprFleetCiiFilters
} from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';
import {
  resetVslCo2EmissionsFilters,
  resetVslFleetCiiFilters,
  updateVslCo2EmissionsFilters,
  updateVslFleetCiiFilters
} from '@store/features/filters/EmissionAnalyticsPage/VesselComparisonReducer/VesselComparisonReducer';
import {
  resetRegionActivityFilters,
  updateRegionActivityFilters
} from '@store/features/filters/RegionProfilePage/RegionActivitySlice';
import {
  resetRegionHistActivityFilters,
  updateRegionHistActivityFilters
} from '@store/features/filters/RegionProfilePage/RegionHistoricalActivitySlice';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import {
  updateCompletedVoyagesFilters,
  resetCompletedVoyagesFilters
} from '@store/features/filters/VoyageAnalyticsPage/CompletedVoyage/CompletedVoyagesSlice';
import { updateVoyageProfileFilters } from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';
import {
  updateOngoingVoyagesFilters,
  resetOngoingVoyagesFilters
} from '../features/filters/VoyageAnalyticsPage/OngoingVoyage/OngoingVoyagesSlice';

const resetFilters = (listenerApi, segment) => {
  listenerApi.dispatch(setOperatorType(OPERATOR_TYPE.ALL));
  listenerApi.dispatch(setCommercialCategoryType(COMMERCIAL_CATEGORY_TYPE.ALL));
  //Commercial analytics
  listenerApi.dispatch(resetActivityMapFilters(segment));
  listenerApi.dispatch(resetPortActivityFilters());
  listenerApi.dispatch(resetServiceLevelFilters());
  listenerApi.dispatch(resetOperatorPerformanceFilters());

  // Operator comparison
  listenerApi.dispatch(resetOprCo2EmissionsFilters(segment));
  listenerApi.dispatch(resetOprFleetCiiFilters({ segment: segment, asset: 'vessel' }));

  // vessel comparison
  listenerApi.dispatch(resetVslCo2EmissionsFilters(segment));
  listenerApi.dispatch(resetVslFleetCiiFilters({ segment: segment, asset: 'vessel' }));

  // Region profile
  listenerApi.dispatch(resetRegionActivityFilters(segment));
  listenerApi.dispatch(resetRegionHistActivityFilters());

  // voyage analytics
  listenerApi.dispatch(resetCompletedVoyagesFilters());
  listenerApi.dispatch(resetOngoingVoyagesFilters());
};

const setFiltersFromState = (listenerApi, sessionState) => {
  const {
    general,
    filters: {
      commercialAnalytics: { activityMap, portActivity, serviceLevel, operatorPerformance },
      voyageAnalytics: { completedVoyages, voyageProfile, ongoingVoyages },
      emissionsAnalytics: {
        operatorComparison: { fleetCiiRating: oprFleetCiiRating, co2Emissions: oprCo2Emissions },
        vesselComparison: { fleetCiiRating: vslFleetCiiRating, co2Emissions: vslCo2Emissions }
      },
      regionProfile: { section: regionActivity, historicalActivity }
    }
  } = sessionState;

  listenerApi.dispatch(setOperatorType(general.operatorType));
  listenerApi.dispatch(setCommercialCategoryType(general.commercialCategoryType));

  listenerApi.dispatch(updateActivityMapFilters(activityMap));
  listenerApi.dispatch(updatePortActivityFilters(portActivity));
  listenerApi.dispatch(updateServiceLevelFilters(serviceLevel));
  listenerApi.dispatch(updateOperatorPerformanceFilters(operatorPerformance));

  // Operator comparison
  listenerApi.dispatch(updateOprCo2EmissionsFilters(oprCo2Emissions));
  listenerApi.dispatch(updateOprFleetCiiFilters(oprFleetCiiRating));

  // vessel comparison
  listenerApi.dispatch(updateVslCo2EmissionsFilters(vslCo2Emissions));
  listenerApi.dispatch(updateVslFleetCiiFilters(vslFleetCiiRating));

  // Region profile
  listenerApi.dispatch(updateRegionActivityFilters(regionActivity));
  listenerApi.dispatch(updateRegionHistActivityFilters(historicalActivity));

  // voyage analytics
  listenerApi.dispatch(updateCompletedVoyagesFilters(completedVoyages));
  listenerApi.dispatch(updateVoyageProfileFilters(voyageProfile));
  listenerApi.dispatch(updateOngoingVoyagesFilters(ongoingVoyages));
};

export const getSegmentChangeMiddleware = () => {
  const segmentChangeMiddleware = createListenerMiddleware();
  segmentChangeMiddleware.startListening({
    actionCreator: setSegment,
    effect: (action, listenerApi) => {
      listenerApi.cancelActiveListeners();
      const controller = new AbortController();
      const { signal } = controller;
      const sessionState = JSON.parse(sessionStorage.getItem(APP_NAME));
      const state = listenerApi.getState();
      let segment = state.general.segment;
      const init = state.general.init;

      // Build state for when user refreshes page
      if (init) {
        // If we have session state, and the user is the same as the one saved in session
        if (
          !!sessionState &&
          sessionState?.isRedux &&
          sessionState?.userId === state?.auth?.user?.id
        ) {
          if (sessionState.general.segment.id === state.general.segment.id) {
            setFiltersFromState(listenerApi, sessionState);
          } else {
            resetFilters(listenerApi, segment);
          }

          //Check if session state is also
        } else if (segment.id === SEGMENT.MPP.id) {
          // The state is initialized with RORO variables so if it's changed to MPP then we should reset state to MPP
          resetFilters(listenerApi, segment);
        }

        // check if user is the same, and that we have session storage
        // if not then check if segment is MPP, if so then reset filters
      } else {
        // State is already set, and segment has changed
        resetFilters(listenerApi, segment);
      }

      listenerApi.dispatch(
        fetchSegmentLookups({
          signal: signal,
          segment: segment
        })
      );
      listenerApi.dispatch(setInit(false));

      return () => {
        controller.abort();
      };
    }
  });
  return segmentChangeMiddleware;
};
