import { Box, Paper, Stack, Typography } from '@esgian/esgianui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';

const RouteIndicatorIcon = ({ color }) => (
  <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="8" height="4" fill={color} />
  </svg>
);

RouteIndicatorIcon.propTypes = {
  color: PropTypes.string
};

const VoyageSummarySpeedIndicatorFloatingWindow = () => {
  const { themeMode } = useSelector(getGeneralSlice);

  return (
    <Paper
      sx={{
        zIndex: 20,
        width: '20rem',
        background: themeMode ? 'rgba(255, 255, 255, 0.80)' : 'rgba(0, 0, 0, 0.80)',
        p: '0.5rem 0rem' // short for padding
      }}>
      <Stack pl={2} pr={2} spacing={0.5}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <RouteIndicatorIcon color={'#EF5350'} />
          <Typography variant={'body2'} color="primary.contrastText">
            {'Vessel Draught >=80%'}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <RouteIndicatorIcon color={'#FF9800'} />
          <Typography variant={'body2'} color="primary.contrastText">
            {'65%<= Vessel Draught <80%'}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <RouteIndicatorIcon color={'#4CAF50'} />
          <Typography variant={'body2'} color="primary.contrastText">
            {'Vessel Draught<65%'}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

export default VoyageSummarySpeedIndicatorFloatingWindow;

VoyageSummarySpeedIndicatorFloatingWindow.propTypes = {
  summaryOverview: PropTypes.object
};
