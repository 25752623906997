import React from 'react';
import { Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import VoyageSummaryDraughtChart from './VoyageSummaryDraughtChart';
import PropTypes from 'prop-types';
function VoyageSummaryDraught({ voyageTransitDetails, isFullScreen, isOngoingVoyage }) {
  const { avgDraught, highestDraught = 0, vesselDraught = 0 } = voyageTransitDetails ?? {};

  return (
    <Paper
      sx={{
        p: isOngoingVoyage ? 0 : 2,
        height: '100%',
        background: isOngoingVoyage ? 'transparent' : undefined
      }}>
      <Stack spacing={2}>
        {!isFullScreen && (
          <Stack spacing={2}>
            <Stack>
              <Typography variant={'h6'}>Draught</Typography>
            </Stack>
            <Grid container sx={{ textAlign: 'center' }}>
              <Grid item xs>
                <Typography variant={'body2'} bold>
                  {avgDraught === null ? '-' : avgDraught.toFixed(1)}
                </Typography>
                <Typography variant={'caption'} color={'text.secondary'}>
                  Avg. Draught (m)
                </Typography>
              </Grid>
              <div>
                <Divider orientation={'vertical'} />
              </div>
              <Grid item xs>
                <Typography variant={'body2'} bold>
                  {highestDraught === null ? '-' : highestDraught.toFixed(1)}
                </Typography>
                <Typography variant={'caption'} color={'text.secondary'}>
                  Maximum Draught (m)
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        )}
        <Stack>
          <VoyageSummaryDraughtChart
            voyageTransitDetails={voyageTransitDetails}
            expectedDraught={vesselDraught}
            isFullScreen={isFullScreen}
            isOngoingVoyage={isOngoingVoyage}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

VoyageSummaryDraught.propTypes = {
  voyageTransitDetails: PropTypes.object.isRequired,
  isFullScreen: PropTypes.bool,
  isOngoingVoyage: PropTypes.bool
};

VoyageSummaryDraught.defaultProps = {
  isFullScreen: false
};

export default VoyageSummaryDraught;
