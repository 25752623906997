import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
  VoyageProgressInformation
} from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import VesselOverview from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection/VesselOverview';
import VesselPositionDetails from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection/VesselPositionDetails/VesselPositionDetails';
import VesselMap from '@components/Maps/VesselMap';
import moment from 'moment/moment';
import VesselSpeedHistogram from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection/VesselSpeedHistogram';
import OwnerOrOperatorHistory from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection/OwnerOrOperatorHistory';
import { useSegment } from '@hooks';

function VesselOverviewSection({
  latestPositionData,
  vesselsDataByFilters,
  portCallData,
  nextPortCallData,
  loading,
  speedHistogramData
}) {
  const { portId, portName, portCode, endDate, startDate } = portCallData ? portCallData[0] : {};
  const {
    portId: etaPortId,
    portName: etaPortName,
    portUnlocode,
    destination,
    eta,
    activePort
  } = nextPortCallData || {};
  const { operatorInformation, ownershipInformation, vessel } = vesselsDataByFilters?.[0] || {};
  const { vesselName } = vessel || {};

  const { isRoRo } = useSegment();

  return (
    <>
      {portCallData !== null && (
        <Grid item xs={12}>
          <Paper>
            <Grid item xs={12} sx={{ p: 2 }} id={'vessel-journey'}>
              <VoyageProgressInformation
                atdPortLink={
                  <ProfileLink linkVariant={'h6'} id={portId} name={portName} profile={'port'} />
                }
                etaPortLink={
                  <ProfileLink
                    disabled={!activePort}
                    linkVariant={'h6'}
                    id={etaPortId}
                    name={etaPortName || destination}
                    profile={'port'}
                  />
                }
                ataDate={moment.utc(startDate)}
                atdPortCode={portCode}
                loading={!portCallData?.length || !nextPortCallData}
                etaDate={moment.utc(eta)}
                etaPortCode={portUnlocode}
                atdDate={endDate}
                id={'vessel'}
              />
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={6}>
        <VesselOverview
          vesselsDataByFilters={vesselsDataByFilters}
          loading={loading}
          isRoRo={isRoRo}
        />
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <Grid container>
            <Grid item xs={6}>
              <VesselPositionDetails
                loading={loading}
                vesselLatestPositionData={latestPositionData}
              />
            </Grid>
            {loading ? (
              <Grid item xs={6}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ) : (
              <Grid item xs={6}>
                <VesselMap loading={loading} vesselLatestPositionData={latestPositionData} />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant={'h6'}>Speed Histogram</Typography>
              <Typography variant={'body2'} color={'text.secondary'}>
                Last 12 months vs {moment().year() - 1}
              </Typography>
            </Stack>
            <Divider />
            <VesselSpeedHistogram
              speedHistogramData={speedHistogramData}
              loading={loading}
              fileName={`${vesselName}-speed-histogram`}
            />
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <OwnerOrOperatorHistory
          ownerOrOperator={'Operator'}
          loading={loading}
          historyData={operatorInformation}
        />
      </Grid>
      {isRoRo && (
        <Grid item xs={6}>
          <OwnerOrOperatorHistory
            ownerOrOperator={'Owner'}
            loading={loading}
            historyData={ownershipInformation}
          />
        </Grid>
      )}
    </>
  );
}

VesselOverviewSection.propTypes = {
  portCallData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  nextPortCallData: PropTypes.object,
  vesselsDataByFilters: PropTypes.object,
  speedHistogramData: PropTypes.arrayOf(PropTypes.object),
  ownerHistoryData: PropTypes.arrayOf(PropTypes.object),

  operatorHistoryData: PropTypes.arrayOf(PropTypes.object),

  latestPositionData: PropTypes.object
};

VesselOverviewSection.defaultProps = {
  portCallData: [],
  loading: false,
  vesselsDataByFilters: null,
  nextPortCallData: null,
  speedHistogramData: [],
  ownerHistoryData: [],
  operatorHistoryData: [],
  latestPositionData: null
};

export default VesselOverviewSection;
