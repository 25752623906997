import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@hooks';
import { checkUserHasAccess } from '@helpers';
import { getUser } from '@store/features';
import {
  Box,
  Divider,
  EditIcon,
  Grid,
  HelpOutlineIcon,
  IconButton,
  InfoOutlineIcon,
  Paper,
  Stack,
  Table,
  ToolTip,
  Typography
} from '@esgian/esgianui';
import UpdateDataModal from './UpdateDataModal';
import {
  removeSpecialCharacters,
  removeWhitespace
} from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';

const TableWithSummary = ({
  columns,
  data,
  title,
  onUpdate,
  summary,
  tableOptions,
  isLoading,
  maxTableHeight,
  minTableHeight
}) => {
  const { theme } = useTheme();
  const user = useSelector(getUser);
  const [dataToUpdate, setDataToUpdate] = useState();
  const [, setHoveredRowColumn] = useState();

  const renderCellContent = (cellData, customCellValue) => {
    if (cellData?.value === undefined || cellData?.value === null) {
      return (
        <Typography variant="body2">{customCellValue ? customCellValue('--') : '--'}</Typography>
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <Typography variant="body2">
          {customCellValue ? customCellValue(cellData.value) : cellData.value}
        </Typography>
        {cellData?.tooltip && (
          <ToolTip title={cellData.tooltip}>
            <IconButton>
              <InfoOutlineIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          </ToolTip>
        )}
      </Box>
    );
  };

  const renderEditButton = (cellData, rowIndex, columnName) => {
    if (!cellData?.isEditable) return null;

    return (
      <Box width="16px">
        <IconButton
          sx={{ ml: 1 }}
          onClick={() => {
            setDataToUpdate({
              rowIndex,
              column: columnName,
              value: cellData.value,
              label: data[rowIndex][columns[0].name].value ?? ''
            });
          }}>
          <EditIcon />
        </IconButton>
      </Box>
    );
  };

  const getTableColumns = () =>
    columns.map((c) => ({
      name: c.name,
      label: c.label,
      options: {
        customBodyRender: (_, rowMeta) => {
          const cellData = data[rowMeta.rowIndex][c.name];
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onMouseEnter={() =>
                cellData?.isEditable && setHoveredRowColumn({ r: rowMeta.rowIndex, c: c.name })
              }
              onMouseLeave={() => cellData?.isEditable && setHoveredRowColumn(undefined)}>
              <Box display="flex" alignItems="center" height={'24px'}>
                {renderCellContent(cellData, c.customCellValue)}
                {renderEditButton(cellData, rowMeta.rowIndex, c.name)}
              </Box>
            </Box>
          );
        },
        customHeadLabelRender: () => (
          <Box display="flex" alignItems="center" my={2}>
            <Typography>{c.label}</Typography>
            {c.tooltip && (
              <ToolTip title={c.tooltip}>
                <IconButton>
                  <HelpOutlineIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </ToolTip>
            )}
          </Box>
        )
      }
    }));

  const getTableData = () =>
    data.map((d) =>
      Object.fromEntries(Object.entries(d).map(([key, value]) => [key, value.value]))
    );

  const defaultTableOptions = {
    search: false,
    print: false,
    filter: false,
    draggable: false,
    sort: false,
    viewColumns: false,
    selectableRows: false,
    customFooter: () => null,
    elevation: 0,
    responsive: 'standard',
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: `${removeSpecialCharacters(
        removeWhitespace(typeof title === 'string' ? title : 'exported_data')
      )}.csv`
    },
    ...tableOptions
  };

  const tableStyles = {
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    },
    MUIDataTable: {
      styleOverrides: {
        responsiveBase: {
          minHeight: minTableHeight,
          maxHeight: maxTableHeight,
          '&::-webkit-scrollbar': {
            width: '7px'
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            background: ({ palette }) => palette.neutral.neutral01
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => theme.palette.primary.main,
            borderRadius: '10px'
          }
        }
      }
    },
    ...(summary &&
      summary.length > 0 && {
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              minHeight: '0px',
              height: '0px'
            },
            actions: {
              '&:last-child': {
                position: 'absolute',
                top: '-120px',
                right: '0px'
              }
            }
          }
        },
        MUIDataTableFilterList: {
          styleOverrides: {
            root: {
              minHeight: '0px',
              height: '0px'
            }
          }
        }
      })
  };

  const renderSummary = () => {
    if (!summary || summary.length === 0) return null;

    return (
      <>
        <Stack sx={{ mt: 2 }}>
          <Typography variant={'subtitle1'}>{title}</Typography>
        </Stack>
        <Grid container sx={{ textAlign: 'center' }}>
          {summary.map((s, i) => (
            <React.Fragment key={s.label}>
              <Grid item xs>
                <Typography bold>{s.value ?? '--'}</Typography>
                <Typography variant={'caption'}>{s.label}</Typography>
              </Grid>
              {i < summary.length - 1 && (
                <div>
                  <Divider orientation={'vertical'} />
                </div>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Paper sx={{ padding: 2, height: '100%' }}>
        <Stack spacing={2}>
          {renderSummary()}
          <Stack spacing={2}>
            <Table
              columns={getTableColumns()}
              data={getTableData()}
              mode={theme.mode}
              options={defaultTableOptions}
              title={summary?.length ? null : title}
              customStyle={tableStyles}
              loading={isLoading}
            />
          </Stack>
        </Stack>
      </Paper>

      {dataToUpdate && (
        <UpdateDataModal
          content={{ value: dataToUpdate.value, label: dataToUpdate.label }}
          onClose={() => setDataToUpdate(undefined)}
          onSubmit={(newValue) => {
            onUpdate && onUpdate(dataToUpdate.rowIndex, dataToUpdate.column, newValue);
            setDataToUpdate(undefined);
          }}
        />
      )}
    </>
  );
};

const tableColumnPropsShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  customCellValue: PropTypes.func
});

const tableDataPropsShape = PropTypes.shape(
  PropTypes.objectOf({
    label: PropTypes.string.isRequired,
    value: PropTypes.number,
    tooltip: PropTypes.string,
    isEditable: PropTypes.bool
  })
);

TableWithSummary.propTypes = {
  columns: PropTypes.arrayOf(tableColumnPropsShape).isRequired,
  data: PropTypes.arrayOf(tableDataPropsShape).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onUpdate: PropTypes.func,
  summary: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  tableOptions: PropTypes.object,
  isLoading: PropTypes.bool,
  minTableHeight: PropTypes.string,
  maxTableHeight: PropTypes.string
};

export default TableWithSummary;
