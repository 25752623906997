import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl, { Map, NavigationControl } from 'mapbox-gl';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { Button, Paper, Typography, CircularProgress } from '@esgian/esgianui';
import './route-map.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { VoyageSummaryFilterKeys } from '../VoyageSummaryConstants';
import {
  createShipIcon,
  getRouteLayer,
  getRouteSource,
  putMarkerOnMap,
  createPulsingMarker,
  getMarkers,
  attachMarkers,
  removeMarkers,
  generatePulsingDotLayerId,
  toggleLayerVisibility,
  attachRouteLayer
} from './utils';
import {
  getVoyageProfileFilters,
  updateVoyageProfileFilters
} from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';

const VOYAGE_ORIGINAL_ROUTE_KEY = 'originalRoute';
const VOYAGE_SEGMENTED_ROUTE_KEY = 'segmentedRoute';
const MAP_SHORTEST_ROUTE_KEY = 'shortestRoute';
const PULSING_MARKER_START_PREFIX = 'start';
const PULSING_MARKER_END_PREFIX = 'end';
const PULSING_MARKER_END_ID = generatePulsingDotLayerId(PULSING_MARKER_END_PREFIX);
const RED_DRAUGHT_THRESHOLD = 80;
const GREEN_DRAUGHT_THRESHOLD = 65;

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const SEGMENT_COLOR = {
  RED: '#EF5350',
  GREEN: '#66BB6A',
  ORANGE: '#FFA726'
};

const getSegmentColor = (maxDraughtPercentage) => {
  if (maxDraughtPercentage >= RED_DRAUGHT_THRESHOLD) return SEGMENT_COLOR.RED;
  if (maxDraughtPercentage < GREEN_DRAUGHT_THRESHOLD) return SEGMENT_COLOR.GREEN;
  return SEGMENT_COLOR.ORANGE;
};

const getSegmentedRoutes = ({ vesselDraught, transitDetailsCollection }) => {
  if (!transitDetailsCollection.length) return [];

  return transitDetailsCollection.reduce((segments, currentPoint, index) => {
    const maxDraughtPercentage = (currentPoint.draught / vesselDraught) * 100;
    const currentColor = getSegmentColor(maxDraughtPercentage);
    if (index === 0 || currentColor !== segments[segments.length - 1].color) {
      segments.push({ route: [currentPoint], color: currentColor });
    } else {
      segments[segments.length - 1].route.push(currentPoint);
    }
    return segments;
  }, []);
};

function VoyageSummaryRouteMap({
  voyageTransitDetails,
  voyagePortCalls,
  shortestPath,
  shouldShowFullscreenMap,
  isOngoingVoyage
}) {
  const themeMode = useSelector(getThemeMode);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    mapOptions: {
      shortestPath: showShortestPath,
      fullscreen,
      port: showPort,
      voyageInfo: showVoyageInfo,
      draughtGrade: showDraughtGrade
    }
  } = useSelector(getVoyageProfileFilters);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const segmentedRoutesRef = useRef([]);
  const routesRef = useRef([]);

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [searchParams] = useSearchParams();

  const selectedDate = +searchParams.get(VoyageSummaryFilterKeys.SELECTED_DATE) || 0;
  const routeMarkersRef = useRef([]);
  const shortestRouteMarkersRef = useRef([]);

  const [shipMarker, setShipMarker] = useState(null);

  const transitDetailsCollection = voyageTransitDetails?.transitDetails ?? [];

  useEffect(() => {
    if (mapRef.current) {
      return;
    }
    const map = new Map({
      renderWorldCopies: true,
      container: mapContainerRef.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [113.55, 22.1667],
      zoom: 1
    });
    mapRef.current = map;

    map.addControl(new NavigationControl({}));

    routeMarkersRef.current = getMarkers(voyagePortCalls?.portCalls ?? [], mapRef.current, {
      portsColor: themeMode ? '#fff' : '#a0d9ef'
    });
    shortestRouteMarkersRef.current = getMarkers([], mapRef.current);

    map.on('load', () => {
      setIsMapLoaded(true);

      const routes = [
        { route: transitDetailsCollection, color: themeMode ? '#80DFEB' : '#1557FF' }
      ];
      routesRef.current = routes;
      segmentedRoutesRef.current = getSegmentedRoutes({
        vesselDraught: voyageTransitDetails.vesselDraught,
        transitDetailsCollection
      });

      if (shortestPath?.shortestPathCoords?.length > 0) {
        const shortestRoute = [{ route: shortestPath.shortestPathCoords, color: '#E989D8' }];
        map.addLayer(
          getRouteLayer(shortestRoute, {
            id: MAP_SHORTEST_ROUTE_KEY,
            visibility: showShortestPath ? 'visible' : 'none'
          })
        );
      }

      const pulsingMarkerStart = createPulsingMarker(
        mapRef.current,
        'rgba(14,81,255,1)',
        !isOngoingVoyage
      );
      const pulsingMarkerEnd = createPulsingMarker(
        mapRef.current,
        isOngoingVoyage ? '#EF5350' : 'rgba(255,152,0)'
      );
      if (transitDetailsCollection.length > 0) {
        putMarkerOnMap(
          map,
          pulsingMarkerStart,
          transitDetailsCollection[0].lon,
          transitDetailsCollection[0].lat,
          PULSING_MARKER_START_PREFIX
        );

        putMarkerOnMap(
          map,
          pulsingMarkerEnd,
          transitDetailsCollection[transitDetailsCollection.length - 1].lon,
          transitDetailsCollection[transitDetailsCollection.length - 1].lat,
          PULSING_MARKER_END_PREFIX
        );
      }

      if (showPort) {
        attachMarkers(routeMarkersRef.current, map);
      }
      if (showShortestPath && showPort) {
        attachMarkers(shortestRouteMarkersRef.current, map);
      }
    });
    return () => map.remove();
  }, [isOngoingVoyage, themeMode]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    const geojsonSource = mapRef.current.getSource(VOYAGE_ORIGINAL_ROUTE_KEY);
    geojsonSource &&
      geojsonSource.setData(
        getRouteSource([{ route: transitDetailsCollection, color: '#80DFEB' }]).data
      );
  }, [transitDetailsCollection]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    const hasRouteLayer = mapRef.current.getLayer(MAP_SHORTEST_ROUTE_KEY);
    if (!hasRouteLayer) {
      return;
    }

    mapRef.current.setLayoutProperty(
      MAP_SHORTEST_ROUTE_KEY,
      'visibility',
      showShortestPath ? 'visible' : 'none'
    );
  }, [showShortestPath, themeMode]);

  useEffect(() => {
    if (!mapRef.current || !isMapLoaded) {
      return;
    }

    const hasRouteLayer = mapRef.current.getLayer(VOYAGE_ORIGINAL_ROUTE_KEY);
    const hasSegmentedRouteLayer = mapRef.current.getLayer(VOYAGE_SEGMENTED_ROUTE_KEY);

    if (!hasRouteLayer) {
      attachRouteLayer(
        mapRef.current,
        routesRef.current,
        VOYAGE_ORIGINAL_ROUTE_KEY,
        showVoyageInfo
      );
    }
    if (!hasSegmentedRouteLayer) {
      attachRouteLayer(
        mapRef.current,
        segmentedRoutesRef.current,
        VOYAGE_SEGMENTED_ROUTE_KEY,
        fullscreen && showVoyageInfo && showDraughtGrade
      );
    }

    if (!showVoyageInfo) {
      mapRef.current.setLayoutProperty(VOYAGE_ORIGINAL_ROUTE_KEY, 'visibility', 'none');
      hasSegmentedRouteLayer &&
        mapRef.current.setLayoutProperty(VOYAGE_SEGMENTED_ROUTE_KEY, 'visibility', 'none');
      return;
    }

    if (showDraughtGrade && fullscreen) {
      mapRef.current.setLayoutProperty(VOYAGE_ORIGINAL_ROUTE_KEY, 'visibility', 'none');
      hasSegmentedRouteLayer &&
        mapRef.current.setLayoutProperty(VOYAGE_SEGMENTED_ROUTE_KEY, 'visibility', 'visible');
    } else {
      mapRef.current.setLayoutProperty(VOYAGE_ORIGINAL_ROUTE_KEY, 'visibility', 'visible');
      hasSegmentedRouteLayer &&
        mapRef.current.setLayoutProperty(VOYAGE_SEGMENTED_ROUTE_KEY, 'visibility', 'none');
    }
  }, [showVoyageInfo, fullscreen, showDraughtGrade, isMapLoaded]);

  useEffect(() => {
    if (!mapRef.current || !routeMarkersRef.current || !shortestRouteMarkersRef.current) {
      return;
    }

    if (showPort) {
      attachMarkers(routeMarkersRef.current, mapRef.current);
    } else {
      removeMarkers(routeMarkersRef.current, mapRef.current);
    }
    if (showShortestPath && showPort) {
      attachMarkers(shortestRouteMarkersRef.current, mapRef.current);
    } else {
      removeMarkers(shortestRouteMarkersRef.current, mapRef.current);
    }
  }, [showShortestPath, showPort]);

  useEffect(() => {
    if (!mapRef.current || !fullscreen) return;
    // Remove previous ship marker
    if (shipMarker) {
      shipMarker.remove();
    }

    const lastTimestamp = transitDetailsCollection[transitDetailsCollection.length - 1]?.timeStamp;

    const isLastPosition =
      !!lastTimestamp &&
      selectedDate >=
        moment(transitDetailsCollection[transitDetailsCollection.length - 1].timeStamp)
          .toDate()
          .getTime();

    if (isLastPosition) {
      toggleLayerVisibility(mapRef.current, PULSING_MARKER_END_ID, true);
      return;
    }
    toggleLayerVisibility(mapRef.current, PULSING_MARKER_END_ID, false);

    // Add new ship marker
    const currentPosition = transitDetailsCollection.find(
      (position) => moment(position.timeStamp).toDate().getTime() === selectedDate
    );

    if (currentPosition) {
      const newShipMarker = new mapboxgl.Marker({
        element: document.createElement('img')
      })
        .setLngLat([currentPosition.lon, currentPosition.lat])
        .setLngLat([currentPosition.lon, currentPosition.lat])
        .addTo(mapRef.current);

      newShipMarker.getElement().src = createShipIcon();
      setShipMarker(newShipMarker);
    } else {
      const prevPositionFromSelectedDateIndex = transitDetailsCollection.findLastIndex(
        (position) => moment(position.timeStamp).toDate().getTime() < selectedDate
      );
      const prevPositionFromSelectedDate =
        transitDetailsCollection[prevPositionFromSelectedDateIndex];
      const nextPositionFromSelectedDate = transitDetailsCollection.find(
        (position) => moment(position.timeStamp).toDate().getTime() > selectedDate
      );

      if (prevPositionFromSelectedDate && nextPositionFromSelectedDate) {
        const prevPosition = [prevPositionFromSelectedDate.lon, prevPositionFromSelectedDate.lat];
        const nextPosition = [nextPositionFromSelectedDate.lon, nextPositionFromSelectedDate.lat];

        const progress =
          (selectedDate - moment(prevPositionFromSelectedDate.timeStamp).toDate().getTime()) /
          (moment(nextPositionFromSelectedDate.timeStamp).toDate().getTime() -
            moment(prevPositionFromSelectedDate.timeStamp).toDate().getTime());
        const currentPosition = [
          prevPosition[0] + (nextPosition[0] - prevPosition[0]) * progress,
          prevPosition[1] + (nextPosition[1] - prevPosition[1]) * progress
        ];
        const newShipMarker = new mapboxgl.Marker({
          element: document.createElement('img')
        })
          .setLngLat(currentPosition)
          .addTo(mapRef.current);
        newShipMarker.getElement().src = createShipIcon();
        setShipMarker(newShipMarker);
      }
    }
  }, [fullscreen, selectedDate]);

  const getOngoingVoyageMapHeight = (height) =>
    !isOngoingVoyage ? height : `calc(${height} - 100px)`;

  return (
    <Paper sx={{ position: 'relative', height: '100%' }}>
      {!fullscreen && (
        <Typography variant={'h6'} sx={{ position: 'absolute', zIndex: 20, p: 2 }}>
          Voyage route
        </Typography>
      )}

      {!fullscreen && (
        <div
          style={{
            position: 'absolute',
            zIndex: 20,
            right: '10px',
            bottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            gap: 2
          }}>
          <Button
            onClick={() => {
              searchParams.set('fullscreen', true);
              navigate({ search: searchParams.toString() }, { replace: true });
              dispatch(updateVoyageProfileFilters({ mapOptions: { fullscreen: true } }));
            }}
            startIcon={<img src="/assets/images/full-screen-icon.png" alt="Full screen" />}
            sx={{ background: 'white', color: 'black' }}>
            <span>Full screen</span>
          </Button>
        </div>
      )}
      <div
        className="voyage-summary-map-container"
        ref={mapContainerRef}
        style={{
          height: fullscreen
            ? shouldShowFullscreenMap
              ? getOngoingVoyageMapHeight('100vh')
              : getOngoingVoyageMapHeight('calc(100vh - 250px)')
            : '400px',
          width: fullscreen ? '100vw' : '100%',
          borderRadius: fullscreen ? 0 : 4
        }}
      />
      {!isMapLoaded && (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: 'calc(50% - 50px)',
            left: 'calc(50% - 50px)'
          }}
          size={50}
        />
      )}
    </Paper>
  );
}

VoyageSummaryRouteMap.propTypes = {
  voyageTransitDetails: PropTypes.object.isRequired,
  voyagePortCalls: PropTypes.object.isRequired,
  shortestPath: PropTypes.object.isRequired,
  shouldShowFullscreenMap: PropTypes.bool,
  isOngoingVoyage: PropTypes.bool
};
VoyageSummaryRouteMap.defaultProps = {
  voyageTransitDetails: {},
  voyagePortCalls: {},
  shortestPath: {},
  shouldShowFullscreenMap: false
};

export default VoyageSummaryRouteMap;
