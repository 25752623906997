import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from '!mapbox-gl';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Slider, Typography } from '@esgian/esgianui';
import moment from 'moment';
import {
  DATE_FORMAT,
  MAP_STYLE_DARK_MODE,
  MAP_STYLE_LIGHT_MODE,
  MAPBOX_API_TOKEN
} from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupRegions, getThemeMode } from '@store/features';
import { getActivityMapFilters } from '@store/features/filters/CommercialAnalyticsPage/ActivityMapSlice/ActivityMapSlice';
import { updateRegionActivityFilters } from '@store/features/filters/RegionProfilePage/RegionActivitySlice';
import { convertPolygonToCoordinates } from '@helpers/mapHelpers';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const showPolygonHover = (map, lookupRegions, id) => {
  let region = lookupRegions.find(({ regionId }) => regionId === id);
  let { polygon } = region;
  const polygonCoordinates = convertPolygonToCoordinates(polygon);

  // Add a data source containing GeoJSON data.
  map.addSource('maine', {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        // These coordinates outline Maine.
        coordinates: [polygonCoordinates]
      }
    }
  });

  // Add a new layer to visualize the polygon.
  map.addLayer({
    id: 'maine',
    type: 'fill',
    source: 'maine', // reference the data source
    layout: {},
    paint: {
      'fill-color': 'rgba(128,223,235,0.43)',
      'fill-opacity': 0.5
    }
  });
  // Add a black outline around the polygon.
  map.addLayer({
    id: 'outline',
    type: 'line',
    source: 'maine',
    layout: {},
    paint: {
      'line-color': '#4DD1E3',
      'line-width': 1
    }
  });
};

const getSliderMarks = (selectedDate) => {
  const dates = [];
  for (let i = 1; i <= 30; i++) {
    dates.push({
      value: 30 - i,
      label: (
        <Typography variant={'caption'}>
          {moment(selectedDate).subtract(i, 'day').format('DD MMM')}
        </Typography>
      )
    });
  }
  dates.push({
    value: 30,
    label: <Typography variant={'caption'}>{moment(selectedDate).format('DD MMM')}</Typography>
  });
  return dates;
};

const createMarker = (
  setHoverValue,
  number,
  regionName,
  regionId,
  lng,
  lat,
  map,
  routeChange,
  dispatch,
  activityMapFilters,
  lookupRegions
) => {
  let isRegion = true;
  if (regionId === 65 || regionId === 55) {
    isRegion = false;
  }

  //removing whitespace for regionName
  const regionShortName = regionName.replace(/\s/g, '-').toLowerCase();

  const color = !number ? '#c2c2c2' : isRegion ? '#00D684' : '#8cdce9';
  let el = document.createElement('div');
  let markerSpan = document.createElement('span');
  const markerStyle = `
      height: 2.2em; 
      width: 2.2em; 
      font-size: 0.875rem;
      background: ${color};`;
  el.className = 'marker am-marker';
  markerSpan.innerHTML = `<div class="marker-number" id="${regionShortName}-marker"><b>${number}</b></div>`;
  markerSpan.style.cssText = markerStyle;

  el.appendChild(markerSpan);
  el.addEventListener('click', () => {
    const {
      selectedDate,
      vesselSizeMin,
      vesselSizeMax,
      minLiftingCapacity,
      maxLiftingCapacity,
      selectedSlideDate,
      selectedOperators
    } = activityMapFilters;
    dispatch(
      updateRegionActivityFilters({
        date: moment(selectedDate, DATE_FORMAT)
          .subtract(30 - selectedSlideDate, 'days')
          .format(DATE_FORMAT),
        selectedOperators: selectedOperators,
        vesselSizeMin: vesselSizeMin,
        vesselSizeMax: vesselSizeMax,
        minLiftingCapacity: minLiftingCapacity,
        maxLiftingCapacity: maxLiftingCapacity
      })
    );
    routeChange(`region/${regionId}?section=3`);
  });

  el.addEventListener('mouseenter', () => {
    markerSpan.style.cssText = `height: 3em; width: 3em ;font-size: 1.1rem; background: ${color};`;
    showPolygonHover(map, lookupRegions, regionId);
    setHoverValue({ name: regionName, isRegion: isRegion });
  });

  el.addEventListener('mouseleave', () => {
    markerSpan.style.cssText = markerStyle;
    setHoverValue(null);
    map.removeLayer('maine');
    map.removeLayer('outline');
    map.removeSource('maine');
  });

  return new mapboxgl.Marker(el).setLngLat([lng, lat]).addTo(map);
};

//TODO remove region logic and go to page instead
function ActivityMap({
  selectedSlideDate,
  setSelectedSlideDate,
  selectedDate,
  activityData,
  loadingData
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [loading, setLoading] = useState(true);
  const [hoverValue, setHoverValue] = useState(null);
  const [mapSlideSelect, setMapSlideSelect] = useState(30);
  const lookupRegions = useSelector(getLookupRegions);
  const themeMode = useSelector(getThemeMode);
  const dispatch = useDispatch();
  const activityMapFilters = useSelector(getActivityMapFilters);

  const navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: themeMode ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [1, 20],
      height: '50vh',
      zoom: 0.9,
      maxZoom: 1.9,
      interactive: true
    });
    map.current.dragRotate.disable();
  }, []);

  useEffect(() => {
    if (!map.current) return;
    map.current.setStyle(themeMode ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE);
  }, [themeMode]);

  useEffect(() => {
    setMapSlideSelect(selectedSlideDate);
  }, [selectedSlideDate]);

  useEffect(() => {
    if (!lookupRegions?.length) return;
    const regionStats = activityData?.regionStats || null;
    document.querySelectorAll('.am-marker').forEach((el) => el.remove());
    lookupRegions.map(({ name, lat, long, regionId }) => {
      let val =
        regionStats?.find(({ regionId: regionIdStat }) => {
          return regionIdStat === regionId;
        })?.count || 0;
      createMarker(
        setHoverValue,
        val,
        name,
        regionId,
        long,
        lat,
        map.current,
        routeChange,
        dispatch,
        activityMapFilters,
        lookupRegions
      );
    });
    setLoading(false);
  }, [lookupRegions, activityData]);

  const sliderMarks = useMemo(() => {
    if (!selectedDate) return [];
    return getSliderMarks(selectedDate);
  }, [selectedDate]);

  return (
    <div id="activity-map">
      <div ref={mapContainer} className="map-container">
        <div
          id="activity-legend"
          className="legend"
          style={{
            backgroundColor: themeMode ? 'rgba(11,26,46,0.8)' : 'rgba(255, 255, 255, 0.8)'
          }}>
          <b style={{ fontSize: '14px' }} id="vessels-label-title">
            Number of vessels in
          </b>
          <div style={{ display: 'flex' }}>
            <span
              className={'pin-region'}
              style={{ backgroundImage: "url('/assets/images/marker-region.svg')" }}
            />
            <span id="region-map-label" style={{ fontSize: '14px' }}>
              Region
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span
              className={'pin-canal'}
              style={{ backgroundImage: "url('/assets/images/marker-canal.svg')" }}
            />
            <span id="canal-map-label" style={{ fontSize: '14px' }}>
              Canal
            </span>
          </div>
        </div>
        {hoverValue && (
          <div
            className="tooltip-display"
            id="region-canal-tooltip"
            style={{
              backgroundColor: themeMode ? 'rgba(11,26,46,0.8)' : 'rgba(255, 255, 255, 0.8)'
            }}>
            <Typography bold variant={'subtitle2'}>
              {hoverValue.isRegion ? 'Region' : 'Canal'}
            </Typography>
            <Typography variant={'body2'}>{hoverValue.name}</Typography>
          </div>
        )}
        {(loading || loadingData) && (
          <div
            style={{
              height: '100%',
              width: '100%',
              background: 'rgb(255 255 255 / 50%)',
              zIndex: 2,
              position: 'absolute'
            }}>
            <CircularProgress
              sx={{
                position: 'absolute',
                top: 'calc(50% - 50px)',
                left: 'calc(50% - 50px)'
              }}
              size={100}
            />
          </div>
        )}
        <div
          className="map-slider"
          style={{
            backgroundColor: themeMode ? 'rgba(11,26,46,0.8)' : 'rgba(255, 255, 255, 0.8)'
          }}>
          <div style={{ width: '98%', display: 'flex', justifyContent: 'center' }}>
            <Slider
              disabled={loading}
              onChange={(event, val) => setMapSlideSelect(val)}
              onChangeCommitted={(event, val) => {
                setSelectedSlideDate(val);
              }}
              marks={sliderMarks}
              step={1}
              max={30}
              min={1}
              value={mapSlideSelect}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ActivityMap.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  activityData: PropTypes.object,
  selectedSlideDate: PropTypes.number,
  loadingData: PropTypes.bool,
  setSelectedSlideDate: PropTypes.func
};

ActivityMap.defaultProps = {
  selectedDate: null,
  activityData: null,
  selectedSlideDate: 30,
  loadingData: false,
  setSelectedSlideDate: null
};

export default ActivityMap;
