import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Modal, Grid, Paper } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import moment from 'moment';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { DATE_TIME_FORMAT } from '@constants';

const tableColumns = [
  {
    name: 'vesselName',
    label: 'Vessel Name',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (dataIndex, rowIndex) => {
        const { rowData } = rowIndex;
        return dataIndex !== null ? (
          <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
        ) : (
          '--'
        );
      }
    }
  },
  {
    name: 'imo',
    label: 'IMO',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'operatorLongName',
    label: 'Operator',
    options: {
      filter: false,
      sort: true,
      display: true,
      customBodyRender: (dataIndex, rowIndex) => {
        const { tableData } = rowIndex;
        const opID = tableData.find(
          ({ operatorLongName }) => operatorLongName === dataIndex
        ).operatorId;
        return dataIndex !== null ? (
          <ProfileLink name={dataIndex} profile={'operator'} id={opID} />
        ) : (
          '--'
        );
      }
    }
  },
  {
    name: 'canalArrival',
    label: 'Arrival',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return moment.utc(value).format(DATE_TIME_FORMAT);
      }
    }
  },
  {
    name: 'canalDeparture',
    label: 'Departure',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return moment.utc(value).format(DATE_TIME_FORMAT);
      }
    }
  },
  {
    name: 'transit',
    label: 'Time in transit (hours)',
    options: {
      sort: true,
      sortThirdClickReset: true
    }
  },
  {
    name: 'headingDirection',
    label: 'Direction',
    options: {
      filter: false,
      sort: true
    }
  }
];

const tableColumnsWT = [
  {
    name: 'vesselName',
    label: 'Vessel Name',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (dataIndex, rowIndex) => {
        const { rowData } = rowIndex;
        return dataIndex !== null ? (
          <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
        ) : (
          '--'
        );
      }
    }
  },
  {
    name: 'imo',
    label: 'IMO',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'operatorLongName',
    label: 'Operator',
    options: {
      filter: false,
      sort: true,
      display: true,
      customBodyRender: (dataIndex, rowIndex) => {
        const { tableData } = rowIndex;
        const opID = tableData.find(
          ({ operatorLongName }) => operatorLongName === dataIndex
        ).operatorId;
        return dataIndex !== null ? (
          <ProfileLink name={dataIndex} profile={'operator'} id={opID} />
        ) : (
          '--'
        );
      }
    }
  },
  {
    name: 'startDate',
    label: 'Arrival',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return moment(value).format(DATE_TIME_FORMAT);
      }
    }
  },
  {
    name: 'endDate',
    label: 'Departure',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return moment(value).format(DATE_TIME_FORMAT);
      }
    }
  },
  {
    name: 'waitTime',
    label: 'Time at anchor (hours)',
    options: {
      sort: true,
      sortThirdClickReset: true
    }
  }
];

function UnderlyingCanalStatsModal({ modalData, modalOpen, setModalOpen, setModalData, isWT }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const modalDataArray = [];
  Object.keys(modalData).forEach((imo) => {
    modalDataArray.push(modalData[imo]);
  });
  let modalTitle = '';
  if (!isNaN(modalDataArray[0].period)) {
    modalTitle = 'Week ';
  }
  modalTitle += `${modalDataArray[0].period} ${modalDataArray[0].year}`;

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: modalTitle.replaceAll(' ', '-')
      },
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '20vh',
      tableBodyHeight: '20vh',
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, [modalDataArray]);
  return (
    <Modal
      handleClose={() => {
        setModalOpen(false);
        setModalData(null);
      }}
      title={modalTitle}
      open={modalOpen}
      size={'lg'}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" sx={{ p: 1 }}>
            {!isWT ? <b>Canal Transits</b> : <b>Anchor events</b>}: {Object.keys(modalData)?.length}
          </Typography>
        </Grid>
      </Grid>

      {modalDataArray && (
        <Paper variant={'outlined'}>
          <Table
            customStyle={{
              MUIDataTableFilterList: { display: 'none' },
              MUIDataTableToolbar: {
                styleOverrides: {
                  root: {
                    minHeight: 0
                  }
                }
              }
            }}
            mode={theme.mode}
            title={''}
            data={modalDataArray}
            columns={!isWT ? tableColumns : tableColumnsWT}
            options={options}
          />
        </Paper>
      )}
    </Modal>
  );
}

UnderlyingCanalStatsModal.propTypes = {
  modalData: PropTypes.object,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired,
  isWT: PropTypes.bool
};

UnderlyingCanalStatsModal.defaultProps = {
  modalData: null,
  selectedLocation: null,
  isWT: false
};

export default UnderlyingCanalStatsModal;
