import React from 'react';
import {
  Paper,
  Typography,
  ShipsIcon,
  DeleteOutlineIcon,
  IconButton,
  CircularProgress,
  Box,
  Button,
  EditIcon,
  Alert,
  AlertTitle,
  Menu,
  MenuItem
} from '@esgian/esgianui';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { getValueUptoTwoDecimalPlaces } from '../VoyageSummarySection/VoyageSummaryRouteMap/utils';
import moment from 'moment';
import { getTransitDays, VoyageType } from '../VoyageUtils';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  getLocationTypeId,
  VESSEL_MAX_TRANSIT_TIME
} from '../../../../constants';

const renderOriginAndDestination = (
  latestPortCall,
  nextPortCall,
  destination,
  onAddDestination,
  isSharedVoyageGroup
) => {
  const textStyle = {
    fontSize: '14px'
  };
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography style={textStyle}>Destination:</Typography>
        {destination ? (
          <Box display="flex" alignItems="center" gap={'4px'}>
            <Typography style={textStyle}>{destination}</Typography>
            {!isSharedVoyageGroup && (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onAddDestination();
                }}
                disabled={isSharedVoyageGroup}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        ) : isSharedVoyageGroup ? null : (
          <Button
            endIcon={<EditIcon />}
            variant="text"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onAddDestination();
            }}
            style={{ fontSize: '12px' }}
            disabled={isSharedVoyageGroup}>
            Add arrival port
          </Button>
        )}
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="caption">Latest Port Call:</Typography>
        <Typography variant="caption">
          {latestPortCall ? `${latestPortCall?.portName}, ${latestPortCall?.countryName}` : '-'}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="caption">Next Port Call:</Typography>
        <Typography variant="caption">
          {nextPortCall ? `${nextPortCall?.portName}, ${nextPortCall?.countryName}` : '-'}
        </Typography>
      </Box>
    </Box>
  );
};

function SavedVoyageCard({
  singleSavedVoyageWithDetails,
  isHovered,
  isDeletingVoyageFromGroup,
  onDelete,
  voyageGroupType,
  voyageLink,
  lookupLocations,
  onMoveToCompletedGroup,
  onAddArrivalPort,
  departureDate,
  isSharedVoyageGroup
}) {
  const themeMode = useSelector(getThemeMode);

  const updateMenuPopupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });

  const isOngoingVoyage = voyageGroupType === VoyageType.ONGOING;

  const {
    toPortId: endPortId,
    toPortType = 1,
    departureDate: savedDepartureDate,
    details
  } = singleSavedVoyageWithDetails;

  const toPortId = +endPortId;

  if (!details) {
    return null;
  }

  const isSavedDepartureDateExceededMaxTransitTime =
    getTransitDays(savedDepartureDate, moment()) > VESSEL_MAX_TRANSIT_TIME;

  const vesselName = details.voyageOverview.vesselName;
  const departurePort = details.voyageOverview?.departurePort ?? '-';
  const arrivalPort = lookupLocations.find(
    (location) => location?.id === toPortId && getLocationTypeId(location?.type) === toPortType
  )?.name;

  let journeyStartDate = moment(departureDate).format(DATE_FORMAT);
  const journeyEndDate = isOngoingVoyage
    ? 'Today'
    : singleSavedVoyageWithDetails.arrivalDate
    ? moment(singleSavedVoyageWithDetails.arrivalDate).format(DATE_FORMAT)
    : '';
  const transitTime = details?.voyageReducedTransitDetails?.transitDays;
  const distance = details?.voyageReducedTransitDetails?.totalDistance;

  const latestPortCall = details?.voyageOverview?.latestPortCall;

  const portCalls = details?.voyagePortCalls?.portCalls ?? [];

  const isCompletedOngoingVoyage =
    isOngoingVoyage &&
    toPortId &&
    portCalls.some((singlePortCall) => singlePortCall.portId === toPortId);

  const cardStyle = { p: 2, height: '100%', my: 2 };
  if (isHovered) {
    cardStyle.background = themeMode ? '#333' : '#ddd';
  }

  const correctVoyageLink = isCompletedOngoingVoyage
    ? voyageLink.replace('completed', 'ongoing')
    : voyageLink;

  return (
    <Link to={correctVoyageLink} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Paper sx={cardStyle}>
        <Box display="flex" justifyContent="space-between" mb={0.5}>
          <Box display="flex" alignItems="center" gap={2}>
            <ShipsIcon color="primary" />
            <Typography variant="body2">{vesselName}</Typography>
          </Box>
          {!isSharedVoyageGroup && (
            <Box height="24px">
              {isDeletingVoyageFromGroup ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete();
                  }}
                  disabled={!!isDeletingVoyageFromGroup}>
                  <DeleteOutlineIcon
                    fontSize="small"
                    sx={{ fill: ({ palette }) => palette.text.primary }}
                    color="primary"
                  />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
        {isCompletedOngoingVoyage ? (
          <Alert
            severity="info"
            action={
              <Box
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                <Button
                  size="small"
                  sx={{ borderRadius: '100px', background: '#80DFEB', height: '28px' }}
                  disabled={isDeletingVoyageFromGroup}
                  {...bindTrigger(updateMenuPopupState)}>
                  Update
                </Button>
                <Menu {...bindMenu(updateMenuPopupState)}>
                  {!isSharedVoyageGroup && (
                    <MenuItem
                      onClick={() => {
                        updateMenuPopupState.close();
                        onDelete();
                      }}>
                      Delete from group
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      updateMenuPopupState.close();
                      onMoveToCompletedGroup && onMoveToCompletedGroup();
                    }}>
                    Move to completed group
                  </MenuItem>
                </Menu>
              </Box>
            }
            sx={{
              my: 2,
              background: '#C4C4C4',
              display: 'flex',
              alignItems: 'center'
            }}>
            <AlertTitle sx={{ color: 'black', p: 0, m: 0, fontSize: '14px' }}>
              The voyage is completed
            </AlertTitle>
          </Alert>
        ) : null}
        {isOngoingVoyage &&
          isSavedDepartureDateExceededMaxTransitTime &&
          !isCompletedOngoingVoyage && (
            <Alert
              severity="info"
              sx={{
                my: 2,
                background: '#C4C4C4',
                display: 'flex',
                alignItems: 'center'
              }}>
              <AlertTitle sx={{ color: 'black', p: 0, m: 0, fontSize: '12px' }}>
                The voyage has crossed the maximum tracking duration of {VESSEL_MAX_TRANSIT_TIME}{' '}
                days. The start date has been modified accordingly.
              </AlertTitle>
            </Alert>
          )}
        <Box>
          {isOngoingVoyage ? (
            <Box>
              {renderOriginAndDestination(
                latestPortCall,
                details.voyageOverview.nextPortCall,
                arrivalPort,
                onAddArrivalPort,
                isSharedVoyageGroup
              )}
            </Box>
          ) : (
            <>
              <Typography variant="body1">
                {departurePort} - {arrivalPort}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {journeyStartDate} - {journeyEndDate}
              </Typography>
            </>
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption">
            Operator: {details?.voyageOverview?.operatorLongName}
          </Typography>
          {isOngoingVoyage && (
            <Typography variant="caption">
              {moment(journeyStartDate).format(DATE_TIME_FORMAT)} - Today
            </Typography>
          )}
          <Typography variant="caption">
            Transit Time (Days): {getValueUptoTwoDecimalPlaces(transitTime)}
          </Typography>
          {!isOngoingVoyage && (
            <Typography variant="caption">Distance (NM): {Math.floor(distance)}</Typography>
          )}
        </Box>
      </Paper>
    </Link>
  );
}

SavedVoyageCard.propTypes = {
  singleSavedVoyageWithDetails: PropTypes.object.isRequired,
  isHovered: PropTypes.bool.isRequired,
  isDeletingVoyageFromGroup: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  voyageGroupType: PropTypes.string,
  voyageLink: PropTypes.string,
  lookupLocations: PropTypes.array.isRequired,
  onMoveToCompletedGroup: PropTypes.func,
  onAddArrivalPort: PropTypes.func,
  departureDate: PropTypes.object,
  isSharedVoyageGroup: PropTypes.bool
};

export default SavedVoyageCard;
