import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextWithTooltipIcon } from '@components';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { formatNumberReadable } from '@helpers';

const getDataShape = (type, wtt, ttw, wtw, tooltip) => ({
  type: { value: type, tooltip },
  wtt: { value: wtt },
  ttw: { value: ttw },
  wtw: { value: wtw }
});

const emissionTableColumns = [
  { name: 'type', label: 'Type' },
  {
    name: 'wtt',
    label: 'WTT',
    tooltip: 'WTT: Well to Tank Emissions. Sometimes also referred as  energy production emission.'
  },
  {
    name: 'ttw',
    label: 'TTW',
    tooltip:
      'TTW: Tank to Wake emission. This is the emission due to fuel combustion in the vessel sometimes referred as operational emission.'
  },
  {
    name: 'wtw',
    label: 'WTW',
    tooltip:
      'WTW: Well to wake emission. This is the total emissions from energy production and operation (WTT +TTW) .'
  }
];

const createSummaryItem = (label, tooltipText, value) => ({
  label: (
    <TextWithTooltipIcon
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '0.1em'
      }}
      label={label}
      tooltipText={tooltipText}
    />
  ),
  value
});

const Co2EmissionTable = ({ ciiRating, isLoading, title }) => {
  const [data, setData] = useState([]);

  const summary = [
    createSummaryItem(
      'Total CO₂ (T) ',
      'Total CO₂ emission( Tank to Wake) of the vessel for the selected voyage',
      formatNumberReadable(ciiRating?.totalCo2Ttw?.toFixed(0)) ?? '--'
    ),
    createSummaryItem(
      `CII (${ciiRating?.ciiDisplayString}) `,
      'Carbon Index Intensity of the vessel for the selected voyage.',
      ciiRating?.ciiValueTtw?.toFixed(2) ?? '--'
    ),
    createSummaryItem(
      'CII ',
      'Carbon Index Intensity Rating of the vessel for the selected voyage. ',
      ciiRating?.ciiRatingTtw ?? '--'
    )
  ];

  useEffect(() => {
    if (!ciiRating) return;

    setData([
      getDataShape(
        'CO₂',
        ciiRating?.totalCo2Wtt,
        formatNumberReadable(ciiRating?.totalCo2Ttw?.toFixed(0)),
        ciiRating?.totalCo2Wtw
      ),
      getDataShape(
        'CII',
        ciiRating?.ciiValueWtt,
        formatNumberReadable(ciiRating?.ciiValueTtw?.toFixed(2)),
        ciiRating?.ciiValueWtw
      )
    ]);
  }, [ciiRating]);

  return (
    <TableWithSummary
      title={title}
      columns={emissionTableColumns}
      data={data}
      summary={summary}
      isLoading={isLoading}
    />
  );
};

Co2EmissionTable.propTypes = {
  ciiRating: PropTypes.object,
  isLoading: PropTypes.bool,
  title: PropTypes.string
};

export default Co2EmissionTable;
