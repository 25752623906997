import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InfoBox,
  ShipsIcon,
  StorageIcon,
  TrendingUpIcon,
  Paper,
  Stack,
  Box,
  DateRangeMobi,
  Select,
  MenuItem,
  Divider,
  NotFound,
  Tabs,
  Tab,
  Slide
} from '@esgian/esgianui';
import { OperatorsTable } from '@components/Tables';
import { useSegment } from '@hooks';
import moment from 'moment';
import {
  OperatorCEUChart,
  OperatorFleetAgeChart
} from '@components/Sections/ShipAnalytics/OperatorProfileSection/SubSections/OperatorOverviewSection';
import { fetchVesselStats } from '@api';
import { DATE_TIME_FORMAT } from '@constants';
import { TextWithTooltipIcon } from '@components';
import { OperatorMultiSelect } from '@components/Inputs';
import { getLookupOperators, getOperatorType, getCommercialCategoryType } from '@store/features';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

const handleVesselCount = (numMonths, operatorFleetData) => {
  // Ensure each element in result is a unique Set
  let result = Array.from({ length: numMonths }, () => new Set());

  operatorFleetData?.forEach(({ vesselTimeSeries }) => {
    let defaultVessels = [...vesselTimeSeries[0].vesselsIn];

    vesselTimeSeries.forEach(({ vesselsIn, vesselsOut }, i) => {
      let currentVessel = [...defaultVessels];

      vesselsOut.forEach(({ imo }) => {
        currentVessel = currentVessel.filter((vessel) => vessel.imo !== imo);
      });

      if (i !== 0) {
        vesselsIn.forEach((vessel) => {
          if (!currentVessel.some(({ imo }) => imo === vessel.imo)) {
            currentVessel.push(vessel);
          }
        });
      }

      currentVessel.forEach(({ imo }) => {
        result[i].add(imo);
      });

      defaultVessels = [...currentVessel];
    });
  });

  result = result.map((month) => month.size);

  return result;
};

const ageInfoDefault = {
  averageAge: 0,
  vesselsAgeRange0To5: 0,
  vesselsAgeRange6To10: 0,
  vesselsAgeRange11To15: 0,
  vesselsAgeRange16To20: 0,
  vesselsAgeRange21To25: 0,
  vesselsAgeRangeGreaterThan25: 0
};
const ceuCapacityDefault = {
  averageCEU: 0,
  totalCEU: 0,
  averageDeadweightTonnage: 0,
  totalDeadweightTonnage: 0
};

function aggregateOperatorData(response, numMonths) {
  let numOperators = response.length;
  const aggregatedData = {
    vesselsAdded: Array(numMonths).fill([]),
    vesselsRemoved: Array(numMonths).fill([]),
    totalVessels: handleVesselCount(numMonths, response),
    operatorAgeInformation: Array.from({ length: numMonths }, () => ({ ...ageInfoDefault })),
    ceuCapacity: Array.from({ length: numMonths }, () => ({ ...ceuCapacityDefault })),
    numVesselsArray: Array(numMonths).fill([])
  };

  response.forEach((operator) => {
    const operatorFleetData = operator.vesselTimeSeries || [];

    operatorFleetData.forEach((item, index) => {
      // Aggregate vesselsAdded
      aggregatedData.vesselsAdded[index].push(...(item.vesselsIn ?? []));

      // Aggregate vesselsRemoved
      aggregatedData.vesselsRemoved[index].push(...(item.vesselsOut ?? []));

      aggregatedData.numVesselsArray[index].push(item.numVessels || 0);

      // Aggregate operatorAgeInformation

      const ageInfo = item.ageInformation || {};
      Object.keys(ageInfo).forEach((key) => {
        aggregatedData.operatorAgeInformation[index][key] += ageInfo[key] ?? 0;
      });

      // Aggregate ceuCapacity
      const capacity = item.capacity || {};
      Object.keys(capacity).forEach((key) => {
        if (key.startsWith('average')) {
          aggregatedData.ceuCapacity[index][key] += capacity[key] * (item.numVessels || 1);
        } else {
          aggregatedData.ceuCapacity[index][key] += capacity[key] || 0;
        }
      });
    });
  });

  aggregatedData.operatorAgeInformation = aggregatedData.operatorAgeInformation.map((item) => {
    let avg = item.averageAge / numOperators;
    return { ...item, averageAge: avg };
  });

  aggregatedData.ceuCapacity = aggregatedData.ceuCapacity.map((item) => {
    let averageCEU = item.averageCEU / numOperators;
    let averageDeadweightTonnage = item.averageDeadweightTonnage / numOperators;
    return { ...item, averageCEU: averageCEU, averageDeadweightTonnage: averageDeadweightTonnage };
  });

  return aggregatedData;
}

function OperatorsOverviewSection({ operatorDetails }) {
  const [init, setInit] = useState(false);
  const [section, setSection] = useState(1);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('ageProfile');
  const [fleetDates, setFleetDates] = useState({
    start: moment().subtract(12, 'months').startOf('month'),
    end: moment()
  });

  const { isRoRo, segment } = useSegment();
  const lookupOperators = useSelector(getLookupOperators);
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const isProd = process.env.CURRENT_ENV === 'prod';

  const { operators, averageAge, averageCapacity, totalCapacity, fleetSize, operatorShortName } =
    operatorDetails || {};

  const filterOptions = [
    { value: 'ceuCapacity', label: isRoRo ? 'CEU Capacity' : 'Total Deadweight' },
    { value: 'ageProfile', label: 'Age Profile' }
  ];

  useEffect(() => {
    if (lookupOperators.length > 0 && !init) {
      setSelectedOperators(lookupOperators);
      setInit(true);
    }
  }, [lookupOperators, init]);

  const handleFilterChange = useCallback(({ selectedOperators }) => {
    setSelectedOperators(selectedOperators);
  }, []);

  const operatorsOverviewQuery = useQuery({
    queryKey: [
      'vesselStats',
      {
        fleetDates: fleetDates,
        selectedOperators: selectedOperators,
        segment: segment.id,
        operatorType: operatorType,
        commercialCategoryType: commercialCategoryType
      }
    ],
    enabled: !!fleetDates && !!selectedOperators.length && !!segment,
    placeholderData: null,
    queryFn: ({ signal }) => {
      const operatorIds = selectedOperators.map((operator) => operator.id);
      let start = moment(fleetDates.start);
      let end = moment(fleetDates.end);
      return fetchVesselStats(
        signal,
        [segment.id],
        [],
        operatorIds,
        isRoRo ? [operatorType] : [],
        isRoRo ? [] : [commercialCategoryType],
        [],
        start.clone().format(DATE_TIME_FORMAT),
        end.clone().format(DATE_TIME_FORMAT)
      )
        .then((response) => {
          if (!response.length) return null;
          const numMonths = end.diff(start, 'months') + 1;
          const aggregatedData = aggregateOperatorData(response, numMonths);
          const categories = response[0].vesselTimeSeries.map((item) => {
            const date = new Date(item.date);
            return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
          });
          return { aggregatedData: aggregatedData, categories: categories };
        })
        .catch((e) => {
          return null;
        });
    }
  });
  const { aggregatedData, categories } = operatorsOverviewQuery.data || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} id={'operator-overview-fleet-size'}>
        <InfoBox
          loading={operatorsOverviewQuery.isFetching}
          mainValue={fleetSize ? fleetSize : ''}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          subHeader={'Vessels'}
          header={'Fleet Size'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
          headerId={'operator-overview-fleet-size-label'}
          mainValueId={'operator-overview-fleet-size-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-avg-age'}>
        <InfoBox
          loading={operatorsOverviewQuery.isFetching}
          mainValue={averageAge ? averageAge.toFixed(1) : ''}
          icon={<TrendingUpIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'Years'}
          header={'Average Age'}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
          headerId={'operator-overview-avg-age-label'}
          mainValueId={'operator-overview-avg-age-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-avg-capacity'}>
        <InfoBox
          loading={operatorsOverviewQuery.isFetching}
          mainValue={averageCapacity ? parseFloat(averageCapacity.toFixed(0)) : '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Average Capacity'}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
          headerId={'operator-overview-avg-capacity-label'}
          mainValueId={'operator-overview-avg-capacity-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-total-capacity'}>
        <InfoBox
          loading={operatorsOverviewQuery.isFetching}
          mainValue={totalCapacity ? totalCapacity : ''}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Total Capacity'}
          colorSecondary={'#4faefd'}
          colorMain={'#5d3afc'}
          headerColor={'#4faefd'}
          headerId={'operator-overview-total-capacity-label'}
          mainValueId={'operator-overview-total-capacity-value'}
        />
      </Grid>
      {!isProd && (
        <Grid item xs={12} id={'operators-overview'}>
          <Paper>
            <Tabs
              variant="scrollable"
              onChange={(_, val) => {
                setSection(val);
              }}
              value={section}>
              <Tab value={1} label="Operators Overview" />
              <Tab value={2} label="Fleet Development" />
            </Tabs>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
          <div>
            <Paper sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Stack>
                  <TextWithTooltipIcon
                    labelVariant={'h6'}
                    label={'Fleet Development\n'}
                    tooltipText={
                      'Please note the fleet development only counts vessels and operators covered by Esgian shipping'
                    }
                  />
                </Stack>
                <Stack direction="row" spacing={4} justifyContent={'space-between'}>
                  <Box sx={{ width: '15em' }}>
                    <DateRangeMobi
                      sx={{
                        inputProps: {
                          InputProps: {
                            sx: { fontSize: '0.875rem' }
                          },
                          defaultValue: undefined,
                          value:
                            fleetDates.start || fleetDates.end
                              ? `${
                                  fleetDates.start
                                    ? moment(fleetDates.start).format('MMM YYYY')
                                    : ''
                                } - ${
                                  fleetDates.end ? moment(fleetDates.end).format('MMM YYYY') : ''
                                }`
                              : null
                        }
                      }}
                      onClose={({ value }) => {
                        if (value[0].isValid()) {
                          setFleetDates((prevState) => ({
                            ...prevState,
                            start: value[0].startOf('month')
                          }));
                        }
                        if (value[1].isValid()) {
                          setFleetDates((prevState) => ({
                            ...prevState,
                            end: value[1].isSameOrAfter(moment(), 'month')
                              ? moment()
                              : value[1].endOf('month')
                          }));
                        }
                      }}
                      dateFormat={'MMM YYYY'}
                      minStartDate={
                        isRoRo ? moment('07-2022', 'MM-YYYY') : moment('07-2023', 'MM-YYYY')
                      }
                      disableFuture
                      startDate={fleetDates.start}
                      endDate={fleetDates.end}
                      placeholder={'Period'}
                      dateWheels={'MMM YYYY'}
                    />
                  </Box>
                  <Grid item xs={12} className={'section-filter'}>
                    <Box sx={{ width: '300px' }}>
                      <OperatorMultiSelect
                        helperText={
                          init && !selectedOperators.length ? 'No operators selected' : ''
                        }
                        error={init && !selectedOperators.length}
                        placeholder={'Select Operators...'}
                        selectedOperators={selectedOperators}
                        operatorsList={lookupOperators || []}
                        sx={{ width: '100%' }}
                        handleChange={(_, values) => {
                          handleFilterChange({ selectedOperators: values });
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ width: '10em' }}>
                      <Select
                        value={selectedFilter}
                        onChange={(e) => setSelectedFilter(e.target.value)}
                        displayEmpty
                        variant={'standard'}
                        labelId="operator-overview-filter"
                        inputProps={{ 'aria-label': 'Filter' }}
                        MenuProps={{ PaperProps: { style: { width: '10em' } } }}
                        sx={{ width: '10em' }}>
                        {filterOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                </Stack>
                <Divider />
                {!operatorsOverviewQuery.data ? (
                  <NotFound
                    show={true}
                    header={'No Matching Result'}
                    text={'Please change your search parameters'}
                  />
                ) : (
                  <>
                    {selectedFilter === 'ageProfile' && (
                      <OperatorFleetAgeChart
                        operatorName={operatorShortName}
                        categories={categories}
                        loading={operatorsOverviewQuery.isFetching}
                        fleetAgeCeuData={aggregatedData.operatorAgeInformation}
                        totalCountVessel={aggregatedData.totalVessels}
                        vesselsAdded={aggregatedData.vesselsAdded}
                        vesselsRemoved={aggregatedData.vesselsRemoved}
                        fileName={`${operatorShortName}-fleet-age`}
                        showForSingleSeries={false}
                      />
                    )}
                    {selectedFilter === 'ceuCapacity' && (
                      <OperatorCEUChart
                        loading={operatorsOverviewQuery.isFetching}
                        CEUCapacityData={aggregatedData.ceuCapacity}
                        fileName={`${operatorShortName}-CEU-capacity`}
                        categories={categories}
                        vesselsAdded={aggregatedData.vesselsAdded}
                        vesselsRemoved={aggregatedData.vesselsRemoved}
                        showForSingleSeries={false}
                      />
                    )}
                  </>
                )}
              </Stack>
            </Paper>
          </div>
        </Slide>
      </Grid>

      <Grid item xs={12}>
        <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
          <div>
            <OperatorsTable
              operators={operators}
              fileName={'operators-overview'}
              loading={operatorsOverviewQuery.isFetching}
            />
          </div>
        </Slide>
      </Grid>
    </Grid>
  );
}

OperatorsOverviewSection.propTypes = {
  loading: PropTypes.bool,
  operatorDetails: PropTypes.object
};

OperatorsOverviewSection.defaultProps = {};

export default OperatorsOverviewSection;
