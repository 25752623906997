import React from 'react';
import { Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import VoyageSummarySpeedChart from './VoyageSummarySpeedChart';
import PropTypes from 'prop-types';
import {} from '../VoyageSummaryRouteMap/utils';
function VoyageSummarySpeed({ voyageTransitDetails, isFullScreen, isOngoingVoyage }) {
  const { avgSpeed, highestSpeed } = voyageTransitDetails ?? {};

  return (
    <Paper
      sx={{
        p: isOngoingVoyage ? 0 : 2,
        height: '100%',
        background: isOngoingVoyage ? 'transparent' : undefined
      }}>
      <Stack spacing={2}>
        {!isFullScreen && (
          <Stack spacing={2}>
            <Stack>
              <Typography variant={'h6'}>Speed</Typography>
            </Stack>
            <Grid container sx={{ textAlign: 'center' }}>
              <Grid item xs>
                {avgSpeed === null ? '-' : avgSpeed.toFixed(1)}
                <Typography variant={'body2'} bold></Typography>
                <Typography variant={'caption'} color={'text.secondary'}>
                  Avg. speed (kts)j
                </Typography>
              </Grid>
              <div>
                <Divider orientation={'vertical'} />
              </div>
              <Grid item xs>
                <Typography variant={'body2'} bold>
                  {highestSpeed === null ? '-' : highestSpeed.toFixed(1)}
                </Typography>
                <Typography variant={'caption'} color={'text.secondary'}>
                  Highest speed (kts)
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        )}
        <Stack>
          <VoyageSummarySpeedChart
            voyageTransitDetails={voyageTransitDetails}
            isfullScreen={isFullScreen}
            isOngoingVoyage={isOngoingVoyage}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

VoyageSummarySpeed.propTypes = {
  speedData: PropTypes.object,
  voyageTransitDetails: PropTypes.object.isRequired,
  isFullScreen: PropTypes.bool,
  isOngoingVoyage: PropTypes.bool
};

VoyageSummarySpeed.defaultProps = {
  isFullScreen: false
};

export default VoyageSummarySpeed;
