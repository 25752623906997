import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Grid, Link, PageHeader, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { getVesselDetailsByFilters } from '@api';
import VesselsOverviewSection from '@components/Sections/ShipAnalytics/VesselsOverviewSection';
import { useSegment } from '@hooks';

function VesselsOverviewPage() {
  const [loading, setLoading] = useState(false);
  const [vesselsData, setVesselsData] = useState(null);
  const { uriExt, segment } = useSegment();
  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getVesselData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          getVesselDetailsByFilters(
            { segmentTypeIds: [segment.id], activeVessels: true },
            signal
          ).then((result) => {
            setVesselsData(result);
          })
        ]);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };

    getVesselData();
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, []);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <PageHeader
          id={'lbl-vessel-overview'}
          loading={loading}
          title={'Vessels Overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="home-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange(`${uriExt}/commercial-analytics`)}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange(`${uriExt}/commercial-analytics`)}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="vessel-overview">
                Vessels Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <VesselsOverviewSection vesselsData={vesselsData} loading={loading} />
      </Grid>
    </Grid>
  );
}

VesselsOverviewPage.propTypes = {};

VesselsOverviewPage.defaultProps = {};

export default VesselsOverviewPage;
